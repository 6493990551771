export default {
  namespaced: true,
  state: {
    patients: []
  },
  mutations: {
    patients(state, payload) {
      state.patients = payload;
    }
  },
  getters: {
    patients(state) {
      return state.patients;
    }
  }
};
