export default router => {
  router.beforeEach((to, from, next) => {
    const shouldRedirect = Object.keys(to.query).includes("goto") && to.path !== "/login";
    if (shouldRedirect) {
      router.push(to.query.goto);
    } else {
      next();
    }
  });
};
