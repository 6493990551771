import { send } from "@/services/Api";

export const postDiagnosisConsiderations = async data => {
  const method = "POST";
  const path = `${document.config.diagnosisConsiderations}${data.diagnosisConsiderationId}/${data.status}`;
  const payload = data.payload;

  try {
    const results = await send({ path, method, payload, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: "Error",
      message: error
    };
    return {};
  }
};
