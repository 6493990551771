export default {
  namespaced: true,
  state: {
    toastableError: {}
  },
  mutations: {
    toastableError(state, payload) {
      state.toastableError = payload;
    }
  },
  getters: {
    toastableError(state) {
      return state.toastableError;
    }
  },
  actions: {
    handleErrorWithToast({ state }, toast) {
      toast(state.toastableError);
    }
  }
};
