import _ from "lodash";

export const KEYS = {
  ACCESS_TOKEN: "accesstoken",
  REFRESH_TOKEN: "refreshtoken",
  ZEUS_TOKEN: "zeustoken",
  ORG_DATA: "orgdata",
  ORG_ID: "orgid",
  ORG_NAME: "orgname",
  SECURITY_DATA: "securitydata",
  PERMISSIONS_DATA: "permissiondata"
};

export const remove = keys => {
  if (!keys) return;

  if (_.isArray(keys)) {
    keys.forEach(key => localStorage.removeItem(key));
  } else {
    localStorage.removeItem(keys);
  }
};

export const get = key => {
  return localStorage.getItem(key);
};

export const getSessionValue = key => {
  return JSON.parse(sessionStorage.getItem(key));
};

export const getSessionKeys = () => {
  return Object.keys(sessionStorage);
};

export const set = (key, value) => {
  localStorage.setItem(key, value);
};

export default {
  delete: remove,
  get,
  getSessionKeys,
  getSessionValue,
  set
};
