import { enrollmentSteps, enrollmentConfig } from "@/components/EnrollmentV2/steps";
import { I } from "@/utils/permissions";

const meta = {
  context: "enrollment"
};

export default [
  {
    path: "/enrollment",
    component: () => import("@/views/EnrollmentV2.vue"),
    props: route => ({
      token: route.query.token,
      ...enrollmentConfig[route.name]
    }),
    children: [
      {
        path: "/",
        name: enrollmentSteps.INITIAL_FEATURES,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/InitialFeatures.vue"),
        meta
      },
      {
        path: "need-help",
        name: enrollmentSteps.NEED_HELP,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/NeedHelp.vue"),
        meta
      },
      {
        path: "get-started",
        name: enrollmentSteps.GET_STARTED,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/GetStarted.vue"),
        meta
      },
      {
        path: "verify-dob",
        name: enrollmentSteps.VERIFY_DOB,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/VerifyDOB.vue"),
        meta
      },
      {
        path: "verify-phone",
        name: enrollmentSteps.VERIFY_PHONE,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/VerifyPhoneNumber.vue"),
        meta
      },
      {
        path: "info",
        name: enrollmentSteps.INFORMATION,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Information.vue"),
        meta
      },
      {
        path: "confirmation",
        name: enrollmentSteps.CONFIRMATION,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Confirmation.vue"),
        meta
      },
      {
        path: "eula",
        name: enrollmentSteps.EULA,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Agreement.vue"),
        meta
      },
      {
        path: "privacy-policy",
        name: enrollmentSteps.PRIVACY,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Agreement.vue"),
        meta
      },
      {
        path: "communication-policy",
        name: enrollmentSteps.COM_POLICY,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Agreement.vue"),
        meta
      },
      {
        path: "finished",
        name: enrollmentSteps.FINISHED,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/Finished.vue"),
        meta
      },
      {
        path: "not-found",
        name: enrollmentSteps.NOT_FOUND,
        component: () => import(/* webpackChunkName: "enrollment" */ "@/components/EnrollmentV2/NotFound.vue"),
        meta
      }
    ]
  },
  {
    path: "/enrollment/approve/",
    component: () => import("@/views/Enrollment.vue"),
    props: { enrollmentType: "approval" },
    meta: { acl: [I.MODIFY_ENROLLMENT] }
  },
  {
    path: "/enrollment-info/",
    component: () => import("@/views/EnrollmentInfo.vue"),
    name: "EnrollmentInfo"
  }
];
