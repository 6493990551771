import { send } from "@/services/Api";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export const getCurrentUserSettings = async id => {
  const method = "get";
  const path = document.config.professionalSettings + id;
  try {
    const results = await send({ path, method, authToken: true });
    return results;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.getCurrentUserSettings,
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
