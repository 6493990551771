import { can, actions, get } from "@/utils/permissions";
import { whatAmI, USER_TYPES } from "@/utils/context";

export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          I: actions
        };
      },
      computed: {
        permissions() {
          if (!this.$store || this.$store.getters["security/permissions"].length === 0) {
            return get();
          }
          return this.$store.getters["security/permissions"];
        },
        isDbugga() {
          return this.$can(this.I.ACCESS_DEBUGGER);
        },
        isPatient() {
          return this.userType === USER_TYPES.PATIENT;
        },
        isProfessional() {
          return this.userType === USER_TYPES.PROFESSIONAL;
        },
        isClientPartner() {
          return this.userType === USER_TYPES.CLIENT_PARTNER;
        },
        userType() {
          return whatAmI(this.permissions);
        },
        zeusToken: {
          get() {
            return this.$store.state.security.zeusToken;
          },
          set(zeusToken) {
            this.$store.commit("security/zeusToken", zeusToken);
          }
        }
      },
      methods: {
        $can(action) {
          return can(action, this.permissions);
        },
        setAuthTokens(data) {
          this.$store.dispatch("security/setAuthTokens", data);
        }
      }
    });
  }
};
