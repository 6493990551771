import { getCurrentProfessional } from "@/services/Api/getCurrentProfessional";
import { formatDisplayName } from "@/utils/formatter";

export default {
  namespaced: true,
  state: {
    currentProfessional: undefined,
    currentProfessionalsettings: undefined,
    loadingCurrentProfessional: false,
    loadingCurrentProfessionalError: false,
    isEulaRead: true
  },
  mutations: {
    currentProfessional(state, payload) {
      state.currentProfessional = payload;
    },
    isEulaRead(state, payload) {
      state.isEulaRead = payload;
    }
  },
  getters: {
    get(state) {
      return state.currentProfessional;
    },
    displayName(state) {
      const professional = state.currentProfessional.account;
      return formatDisplayName(professional.title, professional.firstName, professional.lastName, professional.suffix);
    },
    isEulaRead(state) {
      return state.isEulaRead;
    },
    formattedName(state) {
      const firstName = state.currentProfessional.account.firstName;
      const lastName = state.currentProfessional.account.lastName;
      return `${lastName}, ${firstName}`;
    },
    currentProfessionalInitials(state) {
      const initials = state.currentProfessional.account.firstName.charAt(0) + state.currentProfessional.account.lastName.charAt(0);
      return initials;
    }
  },
  actions: {
    async update(state) {
      const results = await getCurrentProfessional();
      const eulaStatus = Boolean(results.eula);
      state.commit("currentProfessional", results);
      state.commit("isEulaRead", eulaStatus);
    }
  }
};
