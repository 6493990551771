import { getTasks } from "@/services/Api/getTasks";
import { updateTask } from "@/services/Api/updateTask";
import { formatDisplayName } from "@/utils/formatter";

export default {
  namespaced: true,
  state: {
    tasks: [],
    isGettingTasks: false
  },
  mutations: {
    tasks(state, payload) {
      state.tasks = payload;
    },
    toggleTasksLoader(state) {
      state.isGettingTasks = !state.isGettingTasks;
    }
  },
  getters: {
    userTasks(state) {
      return state.tasks;
    },
    isGettingTasks(state) {
      return state.isGettingTasks;
    },
    overdueTasks(state) {
      return getOverdueTasks(state.tasks);
    },
    todaysTasks(state) {
      return getTodaysTasks(state.tasks);
    },
    tomorrowsTasks(state) {
      return getTomorrowsTasks(state.tasks);
    },
    futureTasks(state) {
      return getFutureTasks(state.tasks);
    }
  },
  actions: {
    async update(state) {
      state.commit("toggleTasksLoader");
      const results = await getTasks();
      const formattedTasks = formatTasks(results.data);

      state.commit("tasks", formattedTasks);
      state.commit("toggleTasksLoader");
    },
    async updateSingleTask(state, payload) {
      await updateTask(payload);
      if (payload.callback) {
        payload.callback();
      }
    }
  }
};

function formatTasks(tasks) {
  const formattedTasks = tasks.map(task => {
    // Updates API UTC time to local time
    const utcDate = task.dueDate + "Z";
    const formattedLocalDate = new Date(utcDate);
    formattedLocalDate.setHours(12, 0, 0);
    task.dueDate = formattedLocalDate;
    // Adds current status to available statuses
    task.availableStatuses = [task.status, ...task.availableStatuses];
    // Adds current Assigned Professional to available professionals
    task.availableProfessionals = [task.assignedTo, ...task.availableProfessionals];
    // Generates formatted name for all Available Professionals
    task.availableProfessionals = formatAssignedProfessionals(task.availableProfessionals);

    return task;
  });

  return formattedTasks;
}

function formatAssignedProfessionals(object) {
  const assignedProfs = object.map(prof => {
    return {
      ...prof,
      formattedName: prof.firstName ? formatDisplayName(prof.prefix, prof.firstName, prof.lastName, prof.suffix) : "Care Team"
    };
  });

  return assignedProfs;
}

function getOverdueTasks(tasks) {
  const today = new Date();
  return tasks.filter(task => {
    const notToday = isToday(task.dueDate) != true;
    return task.dueDate.getTime() < today.getTime() && notToday;
  });
}

function getTodaysTasks(tasks) {
  return tasks.filter(task => isToday(task.dueDate));
}

function getTomorrowsTasks(tasks) {
  return tasks.filter(task => isTomorrow(task.dueDate));
}

function getFutureTasks(tasks) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(23, 59, 59);

  return tasks.filter(task => {
    const formattedDate = new Date(task.dueDate);

    return formattedDate > tomorrow;
  });
}

//helper functions

function isToday(date) {
  const passedDate = new Date(date);
  const today = new Date();
  return passedDate.getDate() == today.getDate() && passedDate.getMonth() == today.getMonth() && passedDate.getFullYear() == today.getFullYear();
}

function isTomorrow(passedDate) {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return passedDate.getDate() == tomorrow.getDate() && passedDate.getMonth() == tomorrow.getMonth() && passedDate.getFullYear() == tomorrow.getFullYear();
}
