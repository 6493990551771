import { I, can } from "@/utils/permissions";
import AuthState from "@/utils/Auth";
import auth from "@/utils/Auth";
import store from "@/store";
import { datadogLogs } from "@datadog/browser-logs";

export default router => {
  router.beforeEach(async (to, from, next) => {
    const unauthorizedPath = to.meta.unauthorizedRoute ? to.meta.unauthorizedRoute.name : "Unauthorized";
    const isLoggedIn = (await AuthState.accessToken()) ? true : false;
    const hasPermission = to.meta.acl == undefined || can(to.meta.acl) ? true : false;
    const isOrgSelected = (await AuthState.getOrgId() == undefined) ? false : true;
    const securityData = auth.getSecurityData();
    const orgId = to.query.orgId;
    const userAdminPermissions = [I.MODIFY_USER, I.VIEW_USER, I.ADD_USER, I.VIEW_USER_ROSTER, I.MODIFY_ORGANIZATION];
    const hasUserAdmin = to.meta.acl != undefined && to.meta.acl.some(r=> userAdminPermissions.includes(r)) ? true : false;

    datadogLogs.logger.info("Router activated", { additionalInfo: { route: to.name, isLoggedIn: isLoggedIn, isOrgSelected: isOrgSelected, orgId: orgId, hasPermission: hasPermission, hasUserAdmin: hasUserAdmin } });

    if (hasPermission) {
      if (securityData && !isOrgSelected) {
        if (to.name !== "orgsDashboard") {
          if (to.name == "logout") {
            next();
          } else if (to.name == "Login") {
            next();
          } else if (orgId && securityData[orgId] != undefined) {
            store.dispatch("organizations/AssignOrganizationId", { id: orgId, name: securityData[orgId].organizationName });
            store.commit("setRouterKey", orgId);
            next();
          } else if (hasUserAdmin) {
            next();
          } else {
            next({ name: "orgsDashboard" });
          }
        } else {
          next();
        }
      }
      next();
    } else if (!hasPermission && isLoggedIn) {
      datadogLogs.logger.error("User tried to access not permitted route", { additionalInfo: { route: to.name } });
      const redirect = { name: unauthorizedPath };
      next(redirect);
    } else if (!isLoggedIn) {
      const redirect = { path: "/login", query: { goto: to.fullPath } };
      next(redirect);
    } else {
      next();
    }
  });
};
