import { EventBus } from "@/services/Events.js";
import store from "@/store";
import auth from "@/utils/Auth";
import { datadogLogs } from "@datadog/browser-logs";

export default {
  namespaced: true,
  state: {
    connection: {},
    connected: false,
    subscribeQueue: [],
    unsubscribeQueue: []
  },
  mutations: {
    connection(state, payload) {
      state.connection = payload;
    },
    connected(state, payload) {
      state.connected = payload;
    },
    subscribeQueue(state, payload) {
      state.subscribeQueue = [...state.subscribeQueue, payload];
    },
    unsubscribeQueue(state, payload) {
      state.unsubscribeQueue = [...state.unsubscribeQueue, payload];
    },
    resetsubscriptionQueues(state) {
      state.subscribeQueue = [];
      state.unsubscribeQueue = [];
    }
  },
  getters: {},
  actions: {
    async connect({ commit, dispatch, state }, payload) {
      const token = await auth.accessToken();
      if (!token) {
        return;
      }

      const connection = new WebSocket(`${payload.url}?authorizationToken=${token}`);

      datadogLogs.logger.info("Connecting to websocket...");

      connection.onopen = () => {
        commit("connected", true);
        datadogLogs.logger.info("Connected to websocket", { additionalInfo: { connection: JSON.stringify(connection) } });
        // run subscription cleanup in case anything happened when not connected
        state.subscribeQueue.map(sub => dispatch("subscribe", sub));
        state.unsubscribeQueue.map(sub => dispatch("unsubscribe", sub));
        commit("resetsubscriptionQueues");
      };

      connection.onmessage = m => dispatch("onMessage", m);
      // TODO: evaluate where websocket is used; if it's only used in chatProfessional, then we can move this to chatProfessional
      connection.onclose = () => dispatch("connect", payload);
      commit("connection", connection);
    },
    onMessage({ dispatch }, payload) {
      // payload data should be stringified json, if not just pass whatever we get.
      const message = JSON.parse(payload.data) || payload.data;
      const type = message.sub.type || "";
      const data = { id: message.sub.data || "" };
      const currentInteractionId = store.getters["chatProfessional/currentInteractionId"];

      const listeners = {
        beneficiary: () => {
          EventBus.$emit(document.config.events.beneficiary);
          dispatch("chatProfessional/updateCurrentActiveConvos", null, { root: true });
        },
        organization: () => {
          if (data.id !== currentInteractionId) {
            dispatch("chatProfessional/updateCurrentActiveConvos", null, { root: true });
          }
        },
        interaction: () => {
          // check if the returned interaction id matches the currently selected interaction id
          if (data.id === currentInteractionId) {
            dispatch("chatProfessional/updateMessages", data, { root: true });
          }
        },
        dbugga: () => dispatch("dbugga/handleInboundMessage", message.payload, { root: true })
      };

      if (listeners[type]) {
        listeners[type]();
      } else {
        khanSolo.error("Unknown Websocket message : " + JSON.stringify(message));
      }
    },
    subscribe({ state, commit }, sub) {
      if (!state.connected) {
        commit("subscribeQueue", sub);
      } else {
        state.connection.send(JSON.stringify({ action: "subscribe", sub }));
      }
    },
    unsubscribe({ state, commit }, sub) {
      if (!state.connected) {
        commit("unsubscribeQueue", sub);
      } else {
        state.connection.send(JSON.stringify({ action: "unsubscribe", sub }));
      }
    }
  }
};
