import { send } from "@/services/Api";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export const updateTask = async data => {
  const method = "put";
  const action = data.action ? "/update/" + data.action : "";
  const path = document.config.tasksAPI + "/" + data.id + action;
  const payload = data.payload;
  try {
    const results = await send({ path, method, authToken: true, payload });
    const errorDetails = {
      header: "Success",
      message: "Task Status Updated"
    };
    store.commit("errorHandler/toastableError", errorDetails);
    return results;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.updateTask,
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
