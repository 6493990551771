/* eslint-disable no-console */
import auth from "@/utils/Auth";
import { KEYS } from "@/utils/storage";
import { send as httpSend } from "@/services/Api";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";

const matchKey = (obj, val) => Object.entries(obj).filter(x => parseInt(x[0]) === parseInt(val))[0][1];

export default {
  namespaced: true,

  state: {
    organizations: [],
    expandedChildren: [],
    /**
     * First attempts to find & assign id from localStorage.
     */
    organizationId: auth.getOrgId() || null,
    organizationName: "",
    userSecurityData: null
  },

  getters: {
    /**
     * Retrieves the organizations data list
     * @param {*} state
     * @returns organizations
     */
    getOrganizations(state) {
      return state.organizations;
    },

    /**
     * Retrieves child organizations
     * @param {*} state
     * @returns expandedChildren
     */
    getOrganizationChildren(state) {
      return state.expandedChildren;
    },

    /**
     * Returns organization id from state
     * @param {*} state
     * @returns organizationId
     */
    getOrganizationId(state) {
      return state.organizationId || auth.getOrgId();
    },

    /**
     * Returns organization name from state
     * @param {*} state
     * @returns organizationName
     */
    getOrganizationName(state) {
      return state.organizationName || auth.getOrgName();
    },

    getUserSecurityData(state) {
      return state.userSecurityData;
    }
  },

  mutations: {
    /**
     * Sets the organizations data list
     * @param {} state
     * @param {data, id} payload
     */
    mutateOrganizations(state, payload) {
      state.organizations = payload;
    },

    /**
     * Sets org ids in array
     * @param {*} state
     * @param {*} payload
     */
    mutateChildOrganizations(state, payload) {
      state.expandedChildren = payload;
    },

    mutateChildren(state, payload) {
      const recurse = (arr, idToFind, objToMerge) => {
        return arr.map(obj => {
          const mergeThis = obj.id === idToFind;
          const merged = !mergeThis ? obj : { ...obj, ...objToMerge };

          if (merged.orgs) {
            // If sorting data is necessary
            // merged.orgs.sort((x,y) => y.id - x.id);
            merged.orgs = recurse(merged.orgs, idToFind, objToMerge);
          }

          return merged;
        });
      };

      state.organizations = recurse(state.organizations, payload.id, payload);
    },

    /**
     * Sets the orgId in localStorage
     * Sets the organizationId in state
     * Clears the orgId in localStorage
     * Clears the orgId in state
     * @param {*} state
     * @param {*} payload
     */
    mutateOrganizationId(state, payload) {
      if (payload) {
        state.organizationId = payload;
        auth.setOrgId(payload);
      } else {
        state.organizationId = null;
        auth.removeOrgId(KEYS.ORG_DATA);
      }
    },

    mutateOrganizationName(state, payload) {
      if (payload) {
        state.organizationName = payload;
        auth.setOrgName(payload);
      } else {
        state.organizationName = null;
        auth.removeOrgName();
      }
    },

    mutateUserSecurityData(state, id) {
      state.userSecurityData = matchKey(auth.getSecurityData(), id);
    }
  },

  actions: {
    /**
     * Makes the initial organizations call
     * @param {*} param0
     */
    async AssignOrganization({ commit }) {
      try {
        const path = document.config.organizationsList;
        const method = "get";

        httpSend({ path, method, authToken: true })
          .then(response => {
            commit("mutateOrganizations", response.data);
          })
          .catch(error => {
            this.$ionic.toastController
              .create({
                header: "Failed to load Broadcast Message History",
                message: error,
                duration: 7000,
                position: "top"
              })
              .then(m => m.present());
            khanSolo.log(error);
          });
      } catch (e) {
        this.$ionic.toastController
          .create({
            header: "Error Loading Data",
            message: e,
            duration: 7000,
            position: "top"
          })
          .then(m => m.present());
      }
    },

    /**
     * Colleection of Ids to validate on
     * @param {*} param0
     * @param {*} payload
     */
    async AssignChildOrganizationIds({ commit }, payload) {
      commit("mutateChildOrganizations", payload);
    },

    /**
     *
     * @param {*} param0
     * @param {*} payload
     */
    async AssignChildren({ commit }, payload) {
      commit("mutateChildren", { id: payload.id, orgs: payload.children });
    },

    /**
     * Assigns the organization id selected
     * @param {*} param0
     * @param {*} payload
     */
    async AssignOrganizationId({ commit }, payload) {
      commit("mutateOrganizationId", payload.id);
      commit("mutateOrganizationName", payload.name);
      commit("mutateUserSecurityData", payload.id);
    },

    /**
     * On log in/out, organizations and id are destroyed from localStorage
     * @param {*} param0
     */
    clearAccess({ commit }) {
      commit("mutateOrganizationId", null);
      commit("mutateOrganizationName", null);
    }
  }
};
