import { send } from "./Api";

const baseUrl = () => document.config.enrollmentApiPublic;

const getByToken = async token => {
  const request = {
    path: `${baseUrl()}${token}`,
    method: "get"
  };
  const { data } = await send(request);

  if (data.status !== "200") {
    throw new Error("enrollment not found");
  }

  return data.payload;
};

const requestCall = async (token, phoneNumber) => {
  const request = {
    path: `${baseUrl()}${token}/requestCall?phone=${encodeURIComponent(phoneNumber)}`,
    method: "post"
  };
  await send(request);
};

const sendCode = async (token, phoneNumber) => {
  const request = {
    path: `${baseUrl()}${token}/sendCode?phone=${encodeURIComponent(phoneNumber)}`,
    method: "post"
  };
  await send(request);
};

const verifyCode = async (token, code) => {
  const request = {
    path: `${baseUrl()}${token}/verifyCode?code=${code}`,
    method: "post"
  };
  const { data } = await send(request);
  return data;
};

const submit = async (token, enrollment) => {
  const request = {
    path: `${baseUrl()}${token}`,
    method: "post",
    payload: enrollment
  };
  return send(request);
};

export default {
  getByToken,
  requestCall,
  sendCode,
  verifyCode,
  submit
};
