<template>
  <div class="prime-modal">
    <ion-header>
      <ion-toolbar class="bottom-divider-thin">
        <h6 slot="start">Clinical Data Entry - {{ entryType }}</h6>
        <ion-button slot="end" mode="ios" @click="closeModal()" expand="full" color="medium" fill="clear"><ion-icon name="md-close"></ion-icon></ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content class="prime-modal-content">
      <p>Please provide the reason you are {{ entryType === "reject" ? "rejecting" : entryType === "update" ? "updating" : "approving" }} this clinical data entry:</p>

      <div v-show="errorMsg" class="notifications-well danger-well margin-bottom-ten">
        {{ errorMsg }}
      </div>
      <div v-if="entryType === 'reject'" class="margin-bottom-ten">
        <select class="form-control" @change="checkOption($event)" required>
          <option value="" disabled selected hidden>Select a Rejection Reason</option>
          <option v-for="(val, key) in reasons" :key="key" :value="val.reason">{{ val.reason }}</option>
          <option value="customReason">Other...</option>
        </select>
      </div>
      <div class="marking-div" v-if="customReason">
        <textarea-autosize :placeholder="'Type your reason here...'" class="form-control prime-message-input rejection-note full-height" rows="1" v-model="desiredReason" :min-height="250" :max-height="300" />
      </div>
    </ion-content>
    <ion-footer>
      <ion-grid>
        <ion-row>
          <ion-col class="pad-right-ten full-height overflow-y-scroll">
            <button @click="closeModal" class="prime-button button-block button-secondary">
              <span>Cancel</span>
            </button>
          </ion-col>
          <ion-col class="pad-right-ten">
            <button @click="validate" class="prime-button button-block">
              <span>{{ entryType }}</span>
            </button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-footer>
  </div>
</template>

<script>
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";

export default {
  name: "ModalUpdateGapEntry",
  props: {
    entryType: {
      type: String,
      required: true
    },
    requestId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      errorMsg: undefined,
      newNote: "",
      reasons: undefined,
      customReason: false,
      desiredReason: undefined
    };
  },
  created() {
    this.getReasons();
    this.entryType === "approve" ? (this.customReason = true) : "";
  },
  methods: {
    checkOption(evt) {
      if (evt.target.value == "customReason") {
        this.customReason = true;
        this.desiredReason = undefined;
      } else {
        this.customReason = false;
        this.desiredReason = evt.target.value;
      }

      this.desiredReason ? (this.errorMsg = undefined) : "";
    },
    getReasons() {
      const method = "get";
      const path = document.config.CDERejectionReasons;

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.reasons = response.data;
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error updating Status",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    },
    closeModal() {
      this.$ionic.modalController.dismiss();
    },
    validate() {
      if (this.desiredReason && this.desiredReason.length > 0) {
        this.errorMsg = undefined;
        this.updateStatus();
      } else {
        this.errorMsg = "Please enter a reason";
      }
    },
    updateStatus() {
      const method = "put";
      const path = document.config.putCdeRequest + this.requestId + `/${this.entryType}`;
      const payload = {
        reason: this.desiredReason
      };

      httpSend({ path, method, payload, authToken: true })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success",
              message: "Your Rejection Reason selection has been saved",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
          EventBus.$emit("statusChanged", this.entryType);
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Error updating Status",
              message: error,
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>
<style scoped>
ion-footer {
  padding: 10px;
}

.marking-div {
  height: 70%;
}

h6,
button {
  text-transform: capitalize;
}
</style>
