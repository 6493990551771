import { can, I, get } from "@/utils/permissions";
import storage, { KEYS } from "@/utils/storage";

export const USER_TYPES = {
  PROFESSIONAL: "professional",
  LUMERIS_ADMIN: "lumerisadmin",
  CLIENT_PARTNER: "clientpartner",
  PATIENT: "patient"
};

const hasZeusToken = () => {
  return storage.get(KEYS.ZEUS_TOKEN) || window.location.search.includes("token");
};

export const whatAmI = (permissions = get()) => {
  //override as patient when zeus token is present
  if (hasZeusToken()) {
    return USER_TYPES.PATIENT;
  }

  if (can(I.VIEW_PROGRAMS, permissions)) {
    return USER_TYPES.PROFESSIONAL;
  }
  if (can(I.ACCESS_LUMERIS_ADMIN, permissions)) {
    return USER_TYPES.LUMERIS_ADMIN;
  }
  if (can(I.ACCESS_CLIENT_PARTNER, permissions)) {
    return USER_TYPES.CLIENT_PARTNER;
  }
  if (can(I.ACCESS_SECURE_WEB_CHAT, permissions)) {
    return USER_TYPES.PATIENT;
  }

  return "unknown";
};

export default {
  whatAmI
};
