<template>
  <component :is="tag" :style="style" v-on="$listeners">
    <slot />
  </component>
</template>

<script>
import _ from "lodash";

const palette = {
  danger: "var(--ion-color-danger)",
  "text.secondary": "var(--ion-color-medium-shade)"
};

export default {
  name: "Element",
  props: {
    tag: { type: String, default: "div" },
    // short hand for text-align
    align: String,
    //background-color
    bgcolor: String,
    //color
    color: String,
    //font-size
    fontSize: [String, Number],
    //font-weight
    fontWeight: String,
    //height
    h: [String, Number],
    //margin
    m: [String, Number],
    mt: [String, Number],
    mr: [String, Number],
    mb: [String, Number],
    ml: [String, Number],
    mx: [String, Number],
    //max-width
    maxWidth: [String, Number],
    //min-height
    minHeight: [String, Number],
    //padding
    p: [String, Number],
    pt: [String, Number],
    pr: [String, Number],
    pb: [String, Number],
    pl: [String, Number],
    px: [String, Number],
    // apply standard prime box shadow
    shadows: Boolean,
    //text-align
    textAlign: String,
    //width
    w: [String, Number]
  },
  computed: {
    style() {
      const styles = {
        backgroundColor: palette[this.bgcolor] || this.bgcolor,
        color: palette[this.color] || this.color,
        fontSize: this.css(this.fontSize),
        fontWeight: this.css(this.fontWeight),
        height: this.css(this.h),
        margin: this.css(this.m),
        marginTop: this.css(this.mt),
        marginRight: this.css(this.mr) || this.css(this.mx),
        marginBottom: this.css(this.mb),
        marginLeft: this.css(this.ml) || this.css(this.mx),
        maxWidth: this.css(this.maxWidth),
        minHeight: this.css(this.minHeight),
        padding: this.css(this.p),
        paddingTop: this.css(this.pt),
        paddingRight: this.css(this.pr) || this.css(this.px),
        paddingBottom: this.css(this.pb),
        paddingLeft: this.css(this.pl) || this.css(this.px),
        boxShadow: this.shadows ? "var(--prime-card-shadow)" : null,
        textAlign: this.textAlign || this.align,
        width: this.css(this.w)
      };

      return _.omitBy(styles, _.isNil);
    }
  },
  methods: {
    css(prop) {
      return _.isNumber(prop) ? `${prop}px` : prop;
    }
  }
};
</script>

<style scoped></style>
