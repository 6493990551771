import { send } from "@/services/Api";
import AuthState from "@/utils/Auth";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export default async (convoInitiatorType, pagNum, perPage, orderBy, search, interactionId) => {
  const claims = await AuthState.claims();
  const method = "post";
  const payload = {
    conversationType: convoInitiatorType ? convoInitiatorType : "direct",
    page: pagNum ? pagNum : 1,
    perPage: perPage ? perPage : 20,
    orderBy: orderBy ? orderBy : "desc",
    search: search ? search : "",
    interactionId: interactionId ? interactionId : "",
    orgIds: AuthState.getOrgId() ? AuthState.getOrgId() : claims.orgIds
  };

  if (claims.professionalId) {
    const path = document.config.dashboardApi + claims.professionalId;
    try {
      const results = await send({ path, method, payload, authToken: true });
      store.commit("chatProfessional/totalRecords", results.data.totalRecords);
      return results.data.results;
    } catch (error) {
      const errorDetails = {
        header: copy.api.errors.getActiveConvos,
        message: error
      };
      store.commit("errorHandler/toastableError", errorDetails);
    }
  }
};
