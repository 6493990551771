import { send } from "@/services/Api";
// import AuthState from "@/utils/Auth";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export const getTasksPRTPractice = async () => {
  // const claims = await AuthState.claims();
  const method = "get";
  // const path = document.config.currentProfessionalAPI + claims.professionalId;
  const path = document.config.SITPractice;
  try {
    const results = await send({ path, method, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.getTasks || "Error",
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
    return {};
  }
};
