export const genderOptions = {
  male: "Male",
  female: "Female",
  not_specified: "Not specified"
};

export const professionalRoles = {
  "0": "Select Role",
  "1": "Physician",
  "2": "Medical Director",
  "3": "Nurse Practitioner",
  "4": "Physician Assistant",
  "5": "Administrator",
  "6": "Case Manager",
  "7": "Clinical Staff",
  "8": "Office Manager",
  "9": "Office Staff"
};

export const suffixOptions = ["", "APRN", "ARNP", "BSN", "CNA", "CNS", "D.D.", "D.O.", "DDS", "DNP", "Jr.", "LCSW", "MA", "MD", "MS", "NP", "OT", "PA", "Ph.D.", "Pharm.D.", "PsyD", "PT", "R.Ph.", "RN", "Sr.", "SW"];
export const systemRoles = {
  CLIENT_PARTNER: "Client Partner",
  LUMERIS_ADMIN: "Lumeris Administrator"
};

export const titleOptions = ["", "Mr.", "Mrs.", "Ms.", "Miss", "Dr."];

export default {
  genderOptions,
  professionalRoles,
  suffixOptions,
  titleOptions
};
