import { default as Auth, default as AuthState } from "@/utils/Auth";
import _ from "lodash";
import store from "../store";

export const actions = {
  //Access
  LOGIN: "6",
  VIEW_USER_ROSTER: "49",
  ACCESS_DEBUGGER: "51",
  VIEW_PROGRAMS: "52",
  ACCESS_SECURE_WEB_CHAT: "53",
  ACCESS_CLIENT_PARTNER: "54",
  ACCESS_LUMERIS_ADMIN: "55",
  VIEW_USER_TEMPLATE: "69",
  MODIFY_USER_TEMPLATE: "70",
  VIEW_SUGGESTED_PRODUCTS: "71",
  VIEW_HEALTH_EDUCATION: "72",
  //Patient
  MODIFY_PATIENT: "2",
  VIEW_PATIENT: "5",
  ADD_PATIENT: "33",
  VIEW_DX_INSIGHTS: "80",
  UPDATE_DX_INSIGHTS: "81",
  VIEW_GAPS_IN_CARE: "82",
  UPDATE_GAPS_IN_CARE: "83",
  VIEW_EVENT_NOTIFICATIONS: "93",
  //User
  MODIFY_USER: "3",
  VIEW_USER: "4",
  ADD_USER: "32",
  VIEW_ADVANCED_NOTIFICATIONS: "99",
  //Patient Metadata
  MODIFY_PATIENT_METADATA: "7",
  VIEW_PATIENT_METADATA: "9",
  //User Metadata
  MODIFY_USER_METADATA: "8",
  VIEW_USER_METADATA: "10",
  //Account Program
  MODIFY_ACCOUNT_PROGRAM: "11",
  VIEW_ACCOUNT_PROGRAM: "12",
  //Care Plan
  MODIFY_CARE_PLAN: "13",
  VIEW_CARE_PLAN: "14",
  //Care Team
  MODIFY_CARE_TEAM: "15",
  VIEW_CARE_TEAM: "16",
  //Credentials
  ACCESS_CREDENTIALS: "17",
  //DB Settings
  VIEW_DB_SETTING: "18",
  MODIFY_DB_SETTING: "19",
  //Education
  ACCESS_EDUCATION_SEARCH: "20",
  //Enrollment
  // NOTE: Long enrollment is turned off for now in favor of short enrollment
  // VIEW_ENROLLMENT: "21",
  VIEW_ENROLLMENT: false,
  INITIATE_ENROLLMENT: "22",
  MODIFY_ENROLLMENT: "23",
  //Events
  ACCESS_EVENT_TRIGGER: "58",
  VIEW_ADT_EVENTS: "66",
  VIEW_CARE_GAP_EVENTS: "67",
  VIEW_QUALITY_CAMPAIGN_EVENTS: "68",
  VIEW_VISIT_COORDINATION: "74",
  VIEW_LCMC_EVENTS: "76",
  VIEW_AHP_EVENTS: "90",
  VIEW_ADVANCED_DIRECTIVE: "91",
  VIEW_BHAR_EVENTS: "103",
  VIEW_HEALTHY_HEART_EVENTS: "104",
  //Heartbeat
  VIEW_HEARTBEAT: "24",
  MODIFY_HEARTBEAT: "25",
  //Messaging
  INITIATE_SECURE_CHAT: "36",
  SEND_INBOUND_MESSAGE: "37",
  SEND_MESSAGE: "56",
  CAN_ACCESS_BROADCAST_MESSAGING: "59",
  INITIATE_VOICECALL: "75",
  //Patient Data
  VIEW_PATIENT_DATA: "26",
  VIEW_SDOH: "85",
  VIEW_RISK_DRIVERS: "92",
  EDIT_SDOH: "86",
  MODIFY_PATIENT_DATA: "27",
  SEND_TRANSCRIPT_TO_EHR: "73",
  VIEW_MEDICAL_HISTORY: "98",
  SEND_PATIENT_TO_ELATION: "105",
  //Organizations
  VIEW_ORGANIZATION: "28",
  MODIFY_ORGANIZATION: "29",
  ADD_ORGANIZATION: "50",
  //PHI
  REQUEST_PHI_DETECTION: "30",
  VIEW_PHI_DETECTION: "31",
  //Tasks
  ADD_TASK: "38",
  VIEW_TASK: "39",
  MODIFY_TASK: "40",
  //Templates
  VIEW_TEMPLATE: "41",
  ADD_TEMPLATE: "42",
  MODIFY_TEMPLATE: "43",
  //Virtual Visit
  CAN_INITIATE_VIRTUAL_VISIT: "84",
  //Workflow
  INITIATE_WORKFLOW: "44",
  VIEW_WORKFLOW_LOCK: "45",
  ADD_WORKFLOW_LOCK: "46",
  MODIFY_WORKFLOW_LOCK: "47",
  //CDE
  VIEW_CDE_FORM: "94",
  ADD_CDE_REQUEST: "95",
  VIEW_CDE_REQUEST: "96",
  MODIFY_CDE_REQUEST: "97",
  REVIEW_CDE_REQUEST: "100",
  REVIEW_CDE_REQUEST_FIRST: "101",
  REVIEW_CDE_REQUEST_SECOND: "102",
  //Miscellaneous
  VIEW_LOG: "1",
  EXECUTE_REPORT: "34",
  VIEW_SECURITY_AUDIT: "35",
  ASSIGN_ADMIN_ROLE: "48"
};

export const I = actions;

export const get = () => {
  if (Auth.getSecurityData() && Object.keys(Auth.getSecurityData()).length) {
    if (Object.entries(Auth.getSecurityData()).length === 1) {
      store.dispatch("organizations/AssignOrganizationId", {
        id: Object.keys(Auth.getSecurityData())[0],
        name: Auth.getSecurityData()[Object.keys(Auth.getSecurityData())[0]].organizationName
      });
      return Auth.getSecurityData()[AuthState.getOrgId()].legacy;
    } else if (AuthState.getOrgId() != null) {
      return Auth.getSecurityData()[AuthState.getOrgId()].legacy;
    } else {
      return Object.entries(Auth.getSecurityData())[0][1].legacy;
    }
  } else {
    const permissionsData = Auth.getPermissionsData();

    if (permissionsData && permissionsData.length) {
      return permissionsData;
    } else {
      return [];
    }
  }
};

export const can = (action, permissions = get()) => {
  if (_.isArray(action)) {
    return action.map(act => checkPermission(permissions, act)).every(act => act === true);
  } else {
    return checkPermission(permissions, action);
  }
};

export const permissionsRemark = feature => {
  return `
          <div class="pad-lr-fifteen pad-bottom-five">
          <p>${feature} is currently disabled; contact your Lumeris representative to discuss enabling this feature </p>
          </div>
        `;
};

const checkPermission = (permissions, action) => {
  if (!action || !Object.values(actions).includes(action)) {
    throw new Error("Invalid action provided for permission check");
  }
  return permissions.includes(action);
};

export default {
  can,
  I: actions,
  get
};
