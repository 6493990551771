import { can, I } from "@/utils/permissions";

export default {
  namespaced: true,
  state: {
    dbuggaMode: true,
    messages: []
  },
  mutations: {
    toggleDbuggaMode(state) {
      state.dbuggaMode = !state.dbuggaMode;
    },
    resetDbuggaMode(state, payload) {
      state.dbuggaMode = payload;
    },
    messages(state, payload) {
      state.messages = [...state.messages, payload];
    },
    clearMessages(state) {
      state.messages = [];
    }
  },
  getters: {
    dbuggaMode(state) {
      // only return true when user has debugger access
      return can(I.ACCESS_DEBUGGER) && state.dbuggaMode;
    },
    messages(state) {
      return state.messages;
    }
  },
  actions: {
    updateDbuggaMode({ commit }, lastMessage) {
      if (can(I.ACCESS_DEBUGGER)) {
        const newMode = lastMessage && lastMessage.provider === "dbugga";
        commit("resetDbuggaMode", newMode);
      }
    },
    handleInboundMessage({ commit }, payload) {
      const data = JSON.parse(payload);
      const newMessage = { direction: "dbugga-incoming", item: data.message };
      commit("messages", newMessage);
    }
  }
};
