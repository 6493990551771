<template>
  <ion-grid>
    <div @click="toggleDbuggaMain" v-if="showDbuggaOverlay" id="dbuggaOverlay">
      <BugIcon />
    </div>
    <ion-row id="dbuggaMain" v-if="showDbuggaMain">
      <ion-col v-if="allProps">
        <h1>Props</h1>
        <JsonTree :data="allProps" :level="1" />
      </ion-col>
      <ion-col v-if="allData">
        <h1>Data</h1>
        <JsonTree :data="allData" :level="1" />
      </ion-col>
      <ion-col v-if="allComputedProperties">
        <h1>Computed Properties</h1>
        <JsonTree :data="allComputedProperties" :level="1" />
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
import BugIcon from "@/components/Global/Icons/IconBug";
import JsonTree from "vue-json-tree";

export default {
  name: "Dbugga",
  components: {
    BugIcon,
    JsonTree
  },
  data() {
    return {
      showDbuggaOverlay: true,
      showDbuggaMain: true
    };
  },
  computed: {
    allProps() {
      return this.$parent.$props;
    },
    allData() {
      return this.$parent.$data;
    },
    allComputedProperties() {
      let returnObj = {};
      const varNames = Object.keys(this.$parent._computedWatchers).filter(key => key !== "$screens");
      varNames.map(name => (returnObj[name] = this.$parent[name]));
      return returnObj;
    }
  },
  created() {
    window.oncontextmenu = event => {
      const el = document.elementFromPoint(event.clientX, event.clientY);
      if (el.parentElement.parentElement.id === "dbuggaOverlay") {
        this.toggleDbuggaOverlay();
        return false;
      }
      return true;
    };
  },
  methods: {
    toggleDbuggaMain() {
      this.showDbuggaMain = !this.showDbuggaMain;
    },
    toggleDbuggaOverlay() {
      this.showDbuggaOverlay = !this.showDbuggaOverlay;
      this.showDbuggaMain = false;
    }
  }
};
</script>
<style scoped>
#dbuggaOverlay {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999999;
}
</style>
