import { getCurrentUserSettings } from "@/services/Api/getCurrentUserSettings";
import { getCurrentUserNotifications } from "@/services/Api/getCurrentUserNotifications";
import { send as httpSend } from "@/services/Api";
import AuthState from "@/utils/Auth";
import { toastController } from "@ionic/core";

export default {
  namespaced: true,

  state: {
    professionalSettings: undefined,
    notifications: undefined,
    conversations: [],
    events: [],
    tasks: [],
    allOrgs: false,
    showPresetLimit: false,
    clonedConversationsData: "",
    clonedTasksData: "",
    clonedEventsData: "",
    availablePresets: []
  },

  getters: {
    getConversations(state) {
      return state.conversations;
    },
    getEvents(state) {
      return state.events;
    },
    getTasks(state) {
      return state.tasks;
    },
    getAllOrgs(state) {
      return state.allOrgs;
    },
    getShowPresetLimit(state) {
      return state.showPresetLimit;
    },
    getOrgId(state) {
      return state.notifications.orgId;
    },
    getClonedConversationsData(state) {
      return JSON.stringify(state.clonedConversationsData);
    },
    getClonedTasksData(state) {
      return JSON.stringify(state.clonedTasksData);
    },
    getClonedEventsData(state) {
      return JSON.stringify(state.clonedEventsData);
    },
    getAllClondedData(state) {
      return [state.clonedConversationsData, state.clonedTasksData, state.clonedEventsData].join();
    },
    getPresets(state) {
      return state.availablePresets;
    }
  },

  mutations: {
    professionalSettings(state, payload) {
      state.professionalSettings = payload;
    },
    notifications(state, payload) {
      state.conversations = payload.conversations;
      state.events = payload.events;
      state.tasks = payload.tasks;
      state.allOrgs = payload.allOrgs;
      state.notifications = payload;
      state.showPresetLimit = payload.showPresetLimit;
    },
    mutateOrgs(state, payload) {
      state.allOrgs = payload;
    },

    mutateConversationChenne(state, payload) {
      state.conversations = payload;
    },

    mutateClonedConversationsData(state, payload) {
      state.clonedConversationsData = JSON.stringify(payload);
    },
    mutateClonedTasksData(state, payload) {
      state.clonedTasksData = JSON.stringify(payload);
    },
    mutateClonedEventsData(state, payload) {
      state.clonedEventsData = JSON.stringify(payload);
    },

    mutateInitializeData(state, payload) {
      state.clonedConversationsData = JSON.stringify(payload.conversations);
      state.clonedTasksData = JSON.stringify(payload.tasks);
      state.clonedEventsData = JSON.stringify(payload.events);
      state.availablePresets = payload.availablePresets;
    }
  },

  actions: {
    async update(state, id) {
      const results = await getCurrentUserSettings(id);
      const notificationResults = await getCurrentUserNotifications(id);
      state.commit("notifications", notificationResults.data);
      state.commit("professionalSettings", results.data.payload);
      state.commit("mutateInitializeData", notificationResults.data);
    },

    async setOrgs({ commit }, payload) {
      commit("mutateOrgs", payload);
    },

    async updateConversationChannel({ commit }, payload) {
      commit("mutateConversationChenne", payload);
    },

    async postNotifications({ state, dispatch, commit }, data) {
      const payload = {
        allOrgs: AuthState.getOrgId() ? data.isAllOrgs : true,
        orgId: AuthState.getOrgId() ? AuthState.getOrgId() : 1,
        conversations: state.conversations,
        tasks: state.tasks,
        events: state.events
      }
      const notValid = (item) => item.active === false && item.channel.length;
      const conversationArray = state.conversations.some(notValid);
      const tasksArray = state.tasks.some(notValid);
      const eventsArray = state.events.some(notValid);

      const method = "post";
      const path = document.config.professionalNotificationSettings + data.professionalId;

      try {
        await httpSend({
          path,
          payload,
          method,
          authToken: true
        });
        const toast = await toastController.create({
          header: "Success!",
          message: "Notification settings have been updated",
          duration: 3000,
          color: "primary",
          mode: "ios"
        });
        toast.present();
        //commit("mutateInitializeData");

        dispatch("update", data.professionalId);
      } catch (error) {
        const toast = await toastController.create({
          header: "Error",
          message: "Notification settings could not be saved. Please try again later",
          duration: 5000,
          position: "top"
        });
        toast.present();
        khanSolo.log(error);
      }
    },

    async setClonedConversationsData({ commit }, payload) {
      commit("mutateClonedConversationsData", payload);
    },

    async setClonedTasksData({ commit }, payload) {
      commit("mutateClonedTasksData", payload);
    },

    async setClonedEventsData({ commit }, payload) {
      commit("mutateClonedEventsData", payload);
    }
  }
};
