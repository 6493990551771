import EnrollmentApi from "@/services/EnrollmentApi";

export default {
  namespaced: true,
  state: () => ({
    dob: undefined,
    emailConfirmation: undefined,
    patient: undefined
  }),
  mutations: {
    dob(state, payload) {
      state.dob = payload;
    },
    emailConfirmation(state, payload) {
      state.emailConfirmation = payload;
    },
    patient(state, payload) {
      state.patient = payload;
    }
  },
  actions: {
    async getEnrollment({ commit }, { token }) {
      const enrollment = await EnrollmentApi.getByToken(token);
      commit("patient", enrollment);
    }
  },
  getters: {}
};
