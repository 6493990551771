import { send } from "@/services/Api";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export const getTasks = async () => {
  const method = "get";
  const path = document.config.tasksAPI + "?verbose=true&incompleteAndRecent=8";

  try {
    const results = await send({ path, method, authToken: true });
    return results;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.getTasks,
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
