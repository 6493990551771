export const INITIAL_FEATURES = "enrollment-initial-features";
export const FIRST_STEP = INITIAL_FEATURES;
export const NEED_HELP = "enrollment-need-help";
export const GET_STARTED = "enrollment-get-started";
export const VERIFY_DOB = "enrollment-verify-dob";
export const VERIFY_PHONE = "enrollment-verify-phone";
export const INFORMATION = "enrollment-information";
export const CONFIRMATION = "enrollment-confirmation";
export const EULA = "s98h7z";
export const PRIVACY = "cr7vv7";
export const COM_POLICY = "oqu940";
export const FINISHED = "enrollment-finished";
export const NOT_FOUND = "enrollment-not-found";

const props = override => ({
  backgroundColor: "white",
  showHeader: true,
  ...override
});

export const enrollmentConfig = {
  [INITIAL_FEATURES]: props({ showHeader: false, next: GET_STARTED }),
  [NEED_HELP]: props(),
  [GET_STARTED]: props({ next: VERIFY_DOB }),
  [VERIFY_DOB]: props({ backgroundColor: "inherit", next: VERIFY_PHONE }),
  [VERIFY_PHONE]: props({ backgroundColor: "inherit", next: INFORMATION }),
  [INFORMATION]: props({ next: CONFIRMATION }),
  [CONFIRMATION]: props({ next: FINISHED }),
  [EULA]: props({ backgroundColor: "inherit", headerColor: "white" }),
  [PRIVACY]: props({ backgroundColor: "inherit", headerColor: "white" }),
  [COM_POLICY]: props({ backgroundColor: "inherit", headerColor: "white" }),
  [FINISHED]: props({ showHeader: false }),
  [NOT_FOUND]: props({ showHeader: false })
};

export const enrollmentSteps = {
  FIRST_STEP: INITIAL_FEATURES,
  INITIAL_FEATURES,
  NEED_HELP,
  GET_STARTED,
  VERIFY_DOB,
  VERIFY_PHONE,
  INFORMATION,
  CONFIRMATION,
  EULA,
  PRIVACY,
  COM_POLICY,
  FINISHED,
  NOT_FOUND
};

export default enrollmentSteps;
