import store from "../store";

export const mobilize = (prevPanel, currentPanel, currentCssClass) => {
  if (window.innerWidth < 768) {
    let { currentPanel } = store.state.panelFlow;
    setTimeout(() => {
      let cPanel = document.querySelector(currentPanel);
      if (cPanel) {
        const prefix = "mobile-";
        const classes = cPanel.className.split(" ").filter(c => !c.startsWith(prefix));
        cPanel.className = classes.join(" ").trim();
        cPanel.classList.add(currentCssClass);
      }
    }, 1500);
  }
};

export const isMobile = () => {
  return window.innerWidth < 768;
};
