import { getTasksPRTPractice } from "@/services/Api/getTasksPRTPractice";

export default {
  namespaced: true,
  state: {
    tasksPRTPractice: {
      tasks: []
    },
    currentPRTTask: undefined,
    tasksPRTPhm: undefined
  },
  mutations: {
    tasksPRTPractice(state, payload) {
      state.tasksPRTPractice = payload;
    },
    currentPRTTask(state, task) {
      state.currentPRTTask = task;
    }
  },
  getters: {
    getTasksPractice(state) {
      return state.tasksPRTPractice;
    },
    getCurrentPRTTask(state) {
      return state.currentPRTTask;
    }
  },
  actions: {
    async update(state, id) {
      const results = await getTasksPRTPractice(id);
      // state.commit("tasksPRTPractice", results.data);
      state.commit("tasksPRTPractice", results);
    },
    async currentTask(state, task) {
      // alert(JSON.stringify(task));
      state.commit("currentPRTTask", task);
    }
  }
};
