import { send } from "@/services/Api";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export default async (id, pageNumber = 1, messagesPerPage = 10) => {
  const method = "get";
  const path = document.config.messageApi + id + "/messages/byPage" + "?page=" + pageNumber + "&perPage=" + messagesPerPage;
  try {
    const results = await send({ path, method, authToken: true });
    return results.data;
  } catch (error) {
    const errorDetails = {
      header: copy.api.errors.getMessages,
      message: error
    };
    store.commit("errorHandler/toastableError", errorDetails);
  }
};
