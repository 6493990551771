import Vue from "vue";
import Router from "vue-router";

import general from "./routes/general";
import enrollment from "./routes/enrollment";
import education from "./routes/education";
import patient from "./routes/patient";
import professional from "./routes/professional";
import programs from "./routes/programs";

import securityGuard from "./guards/security";
import goToGuard from "./guards/goto";
import store from "../store";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...general, ...enrollment, ...education, ...professional, ...patient, ...programs]
});

const nonBackRoutes = ["/professional/tasks", "/programs/", "/professional/roster"];

router.afterEach(async to => {
  if (nonBackRoutes.includes(to.fullPath)) {
    store.commit("setPanelFlow", { prevPanel: "" });
  }
});

securityGuard(router);
goToGuard(router);

export default router;
