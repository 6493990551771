import { send } from "@/services/Api";
import AuthState from "@/utils/Auth";
import copy from "@/assets/languagePack/english";
import store from "@/store";

export default async convoInitiatorType => {
  const claims = await AuthState.claims();
  const method = "post";
  const payload = {
    conversationType: convoInitiatorType ? convoInitiatorType : "direct",
    page: 1,
    perPage: 1,
    orderBy: "desc",
    search: "",
    interactionId: "",
    orgIds: AuthState.getOrgId() ? AuthState.getOrgId() : claims.orgIds
  };

  if (claims.professionalId) {
    const path = document.config.dashboardApi + claims.professionalId;
    try {
      const results = await send({ path, method, payload, authToken: true });
      return results.data;
    } catch (error) {
      const errorDetails = {
        header: copy.api.errors.getConvosCount,
        message: error
      };
      store.commit("errorHandler/toastableError", errorDetails);
    }
  }
};
