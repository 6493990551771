import { I } from "@/utils/permissions";

import BulkCDE from "@/components/Settings/Patient/BulkCDE.vue";

export default [
  {
    path: "/professional",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "",
        name: "patientView",
        component: () => import("@/components/Chat/ChatProfessional.vue"),
        meta: { acl: [I.VIEW_PROGRAMS] }
      },
      {
        path: "dashboard",
        name: "orgsDashboard",
        component: () => import("@/views/OrgsList.vue"),
        meta: { acl: [I.VIEW_PROGRAMS] }
      },
      {
        path: "roster-professional",
        name: "RosterProfessional",
        component: () => import("@/components/Roster/RosterProfessional.vue"),
        meta: { acl: [I.VIEW_USER_ROSTER] }
      },
      {
        path: "dbugga",
        name: "dbugga",
        component: () => import("@/views/dbugga.vue"),
        meta: { acl: [I.ACCESS_DEBUGGER] }
      },
      {
        path: "tasks",
        name: "tasksView",
        component: () => import("@/components/Tasks/Tasks.vue"),
        meta: { acl: [I.VIEW_TASK] }
      },
      {
        path: "roster",
        name: "rosterView",
        component: () => import("@/components/Roster/RosterPatient.vue"),
        meta: { acl: [I.VIEW_PATIENT] }
      },
      {
        name: "events",
        path: "events",
        component: () => import("@/views/Events.vue"),
        meta: { acl: [I.ACCESS_EVENT_TRIGGER] }
      },
      {
        name: "EventHistory",
        path: "event-history",
        component: () => import("@/components/Events/EventHistory.vue"),
        meta: { acl: [I.ACCESS_EVENT_TRIGGER] }
      },
      {
        path: "/patient-settings",
        component: () => import("@/components/Settings/Patient/SettingsMain.vue"),
        children: [
          {
            name: "general-info",
            path: "general-info/:id",
            component: () => import("@/components/Settings/Patient/GeneralInfo.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "conversations",
            path: "conversations/:id",
            component: () => import("@/components/Settings/Patient/Conversations.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "tasks",
            path: "tasks/:id",
            component: () => import("@/components/Settings/Patient/Tasks.vue"),
            meta: { acl: [I.VIEW_PATIENT, I.VIEW_TASK] }
          },
          {
            name: "event-notifications",
            path: "event-notifications/:id",
            component: () => import("@/components/Settings/Patient/EventNotifications.vue"),
            meta: { acl: [I.VIEW_PATIENT, I.VIEW_EVENT_NOTIFICATIONS] }
          },
          {
            name: "diagnosis-considerations",
            path: "diagnosis-considerations/:id",
            component: () => import("@/components/Settings/Patient/DiagnosisInsights.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "quality-considerations",
            path: "quality-considerations/:id",
            component: () => import("@/components/Settings/Patient/GapsInCare.vue"),
            props: true,
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "quality-considerations-bulk",
            path: "quality-considerations-bulk/:id",
            component: () => import("@/components/Settings/Patient/BulkCDE.vue"),
            props: true,
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "medical-history",
            path: "medical-history/:id",
            component: () => import("@/components/Settings/Patient/MedicalHistory.vue"),
            props: true,
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "programs",
            path: "programs/:id",
            component: () => import("@/components/Settings/Patient/Programs.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "care-team",
            path: "care-team/:id",
            component: () => import("@/components/Settings/Patient/CareTeam.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          },
          {
            name: "comm-prefs",
            path: "comm-prefs/:id",
            component: () => import("@/components/Settings/Patient/Settings.vue"),
            meta: { acl: [I.VIEW_PATIENT] }
          }
        ]
      },
      {
        path: "broadcast-message",
        name: "broadcast-message",
        component: () => import("@/views/BroadcastMessage.vue"),
        meta: { acl: [I.CAN_ACCESS_BROADCAST_MESSAGING] }
      },
      {
        name: "BroadcastMessageHistory",
        path: "broadcast-message-history",
        component: () => import("@/components/BroadcastMessage/BroadcastMessageHistory.vue")
      },
      {
        path: "/user-settings/:id",
        component: () => import("@/components/Settings/User/Settings.vue"),
        children: [
          {
            name: "user-profile",
            path: "profile",
            component: () => import("@/components/Settings/User/UserProfile.vue"),
            meta: { acl: [I.VIEW_USER] }
          },
          {
            name: "account-preferences",
            path: "account",
            component: () => import("@/components/Settings/User/AccountPreferences.vue"),
            meta: { acl: [I.VIEW_USER] }
          },
          {
            name: "user-notifications",
            path: "notifications",
            component: () => import("@/components/Settings/User/Notifications.vue"),
            meta: { acl: [I.VIEW_USER] }
          },
          {
            name: "user-care-team",
            path: "care-team",
            component: () => import("@/components/Settings/User/CareTeam.vue"),
            meta: { acl: [I.VIEW_USER] }
          }
        ]
      },
      {
        path: "/practice-admin",
        name: "PracticeAdmin",
        component: () => import("@/components/Settings/Practice/PracticeOverview.vue"),
        meta: { acl: [I.MODIFY_ORGANIZATION] }
      },
      {
        path: "/practice-admin/:id",
        name: "PracticeAdminDetail",
        component: () => import("@/components/Settings/Practice/Settings.vue"),
        meta: { acl: [I.MODIFY_ORGANIZATION] },
        children: [
          {
            name: "general-settings",
            path: "general-settings",
            component: () => import("@/components/Settings/Practice/GeneralSettings.vue"),
            meta: { acl: [I.MODIFY_USER] }
          },
          {
            name: "task-assignments",
            path: "task-assignments",
            component: () => import("@/components/Settings/Practice/TaskAssignments.vue"),
            meta: { acl: [I.MODIFY_USER] }
          },
          {
            name: "notification-assignments",
            path: "notification-assignments",
            component: () => import("@/components/Settings/Practice/NotificationAssignments.vue"),
            meta: { acl: [I.MODIFY_USER] }
          }
        ]
      }
    ]
  },
  {
    //path: "*",
    path: "/:pathMatch(.*)*",
    component: () => import("@/components/Global/PageNotFound.vue")
  }
];
