import router from "@/router";
import { send as httpSend } from "@/services/Api";
import storage, { KEYS } from "@/utils/storage";
import { datadogLogs } from "@datadog/browser-logs";
import VueJwtDecode from "vue-jwt-decode";

function accessToken() {
  return new Promise(async resolve => {
    let authToken = getAccessToken();
    const refreshToken = getRefreshToken();

    if (refreshToken && (!authToken || isExpired(authToken))) {
      try {
        // TODO: check the refresh token - has it expired
        const newToken = await useRefreshToken(refreshToken);
        authToken = newToken;
      } catch (err) {
        router.push("/login");
        throw err;
      }
    }

    resolve(authToken);
  });
}

function useRefreshToken(refreshToken) {
  if (isExpired(refreshToken)) {
    return Promise.reject(new Error("Refresh token is expired"));
  }

  return new Promise(async (resolve, reject) => {
    const method = "post";
    const path = document.config.refreshTokenApi;
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Refresh ${refreshToken}`
    };

    // go get that new token goodness
    try {
      const response = await httpSend({ method, path, headers });
      const token = response.data.access_token;
      setAccessToken(token);

      resolve(token);
    } catch (err) {
      reject(err);
    }
  });
}

const setOrgName = data => {
  window.localStorage.setItem(KEYS.ORG_NAME, JSON.stringify(data));
};

const getOrgName = () => {
  return JSON.parse(window.localStorage.getItem(KEYS.ORG_NAME));
};

const removeOrgName = () => {
  window.localStorage.removeItem(KEYS.ORG_NAME);
};

const getSecurityData = () => {
  return JSON.parse(window.localStorage.getItem(KEYS.SECURITY_DATA));
};

const getPermissionsData = () => {
  return JSON.parse(window.localStorage.getItem(KEYS.PERMISSIONS_DATA));
};

const setSecurityData = data => {
  window.localStorage.setItem(KEYS.SECURITY_DATA, JSON.stringify(data));
};

const removeSecurityData = () => {
  window.localStorage.removeItem(KEYS.SECURITY_DATA);
};

const setPermissionsData = data => {
  window.localStorage.setItem(KEYS.PERMISSIONS_DATA, JSON.stringify(data));
};

const removePermissionsData = () => {
  window.localStorage.removeItem(KEYS.PERMISSIONS_DATA);
};

const setOrgData = data => {
  window.localStorage.setItem(KEYS.ORG_DATA, JSON.stringify(data));
};

const getOrgData = () => {
  return JSON.parse(window.localStorage.getItem(KEYS.ORG_DATA));
};

const removeOrgData = () => {
  window.localStorage.removeItem(KEYS.ORG_DATA);
};

const setOrgId = id => {
  window.localStorage.setItem(KEYS.ORG_ID, id);
};

const getOrgId = () => {
  return window.localStorage.getItem(KEYS.ORG_ID);
};

const removeOrgId = () => {
  window.localStorage.removeItem(KEYS.ORG_ID);
};

const getAccessToken = () => {
  return getToken(KEYS.ACCESS_TOKEN);
};

const setAccessToken = token => {
  storage.set(KEYS.ACCESS_TOKEN, token);
};

const getRefreshToken = () => {
  return getToken(KEYS.REFRESH_TOKEN);
};

const setRefreshToken = token => {
  storage.set(KEYS.REFRESH_TOKEN, token);
};

const getToken = key => {
  const token = storage.get(key);
  if (isExpired(token)) {
    // can't call getRefreshToken here, it will cause an infinite loop
    const refreshToken = storage.get(KEYS.REFRESH_TOKEN);

    if (isExpired(refreshToken)) {
      window.localStorage.removeItem(KEYS.REFRESH_TOKEN);
      window.localStorage.removeItem(KEYS.ACCESS_TOKEN);
      window.localStorage.removeItem(KEYS.SECURITY_DATA);
    }

    if (refreshToken && !isExpired(refreshToken)) {
      datadogLogs.logger.info("calling useRefreshToken using storage.get(KEYS.REFRESH_TOKEN)");
      useRefreshToken(refreshToken);

      return storage.get(KEYS.ACCESS_TOKEN);
    }
  }

  return token;
};

const isExpired = (token, skew = 30000) => {
  if (!token) {
    datadogLogs.logger.info("In isExpired !Token, returning true");
    return true;
  }

  const jwt = VueJwtDecode.decode(token);
  const expires = new Date(jwt.exp * 1000 - skew);
  return expires < Date.now();
};

const getClaims = token => {
  const at = token || getAccessToken();
  return at ? VueJwtDecode.decode(at) : null;
};

const getIdToken = () => {
  const oidcKey = storage.getSessionKeys().find(key => key.startsWith("oidc.user"));
  if (!oidcKey) {
    return null;
  }

  const key = storage.getSessionValue(oidcKey);
  return key ? key.id_token : undefined;
};

export default {
  isLoggedIn: () => Boolean(storage.get(KEYS.ACCESS_TOKEN) || storage.get(KEYS.REFRESH_TOKEN)),
  accessToken,
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  claims: getClaims,
  getIdToken,
  setOrgData,
  getOrgData,
  removeOrgData,
  setOrgId,
  getOrgId,
  removeOrgId,
  setSecurityData,
  getSecurityData,
  setPermissionsData,
  removeSecurityData,
  removePermissionsData,
  getPermissionsData,
  setOrgName,
  getOrgName,
  removeOrgName,
  getIdToken
};
