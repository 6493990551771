<template>
  <ion-app>
    <router-view id="app" :key="refreshKey" />
  </ion-app>
</template>

<script>
import { datadogLogs } from "@datadog/browser-logs";

export default {
  data() {
    return {
      refreshKey: ""
    };
  },
  created() {
    if (window.environment == "prod" || window.environment == "srp") {
      datadogLogs.init({
        clientToken: document.config.clientToken,
        site: "datadoghq.com",
        service: "engage-webapp-console-" + window.environment,
        env: window.environment,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: "all",
        sessionSampleRate: 100
      });
    }
  },
  onIdle() {
    if (this.$authState.isLoggedIn()) {
      // dismiss modal and popovers
      const allModals = document.querySelectorAll("ion-modal");
      allModals.forEach(ele => {
        ele.remove();
      });
      this.$ionic.popoverController.dismiss();
      this.$ionic.modalController.dismiss();

      window.localStorage.removeItem("currentPresetName");
      window.localStorage.removeItem("roster.columnState");
      window.localStorage.removeItem("roster.filterModel");

      this.$router.replace({ path: "/logout" });

      this.$ionic.toastController
        .create({
          header: "Your session has timed out",
          message: "Please login again",
          duration: 10000,
          position: "top"
        })
        .then(m => m.present());
    }
  },
  computed: {
    toastableError() {
      return this.$store.getters["errorHandler/toastableError"];
    }
  },
  watch: {
    "$route.params.id"(val) {
      this.refreshKey = val;
    },
    toastableError(newVal) {
      if (Object.keys(newVal).length > 0) {
        this.$store.dispatch("errorHandler/handleErrorWithToast", this.showToast);
      }
    }
  },
  methods: {
    showToast(details) {
      const { header, message, duration = 5000, color = "primary" } = details;
      this.$ionic.toastController
        .create({
          header,
          message,
          duration,
          color,
          dismissOnPageChange: true,
          mode: "ios"
        })
        .then(m => m.present());
    }
  }
};
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=IBM+Plex+Sans:400,500,600,700&display=swap");
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-weight: 400;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}
#app h1 {
  font-size: 46px;
  font-weight: 700;
}
#app h2 {
  font-size: 34px;
  font-weight: 700;
  margin-top: 24px;
}
#app h3 {
  font-size: 28px;
  font-weight: 700;
}
#app h4 {
  font-size: 26px;
  font-weight: 700;
}
#app h5 {
  font-size: 18px;
  font-weight: 700;
}
#app h6 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 8px;
}

#app h7 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

.h7 {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 8px;
}

#app h1.view-title {
  margin-top: 0px;
}
#app ion-card-header h5,
#app ion-card-header h6 {
  margin: 0;
}
/* Global Utility Classes */
.margin-top-zero {
  margin-top: 0px;
}
.margin-top-five {
  margin-top: 5px;
}
.margin-top-four {
  margin-top: 4px;
}
.margin-top-ten {
  margin-top: 10px;
}
.margin-top-fifteen {
  margin-top: 15px;
}
.margin-top-twenty {
  margin-top: 20px;
}
.margin-top-twentyfive {
  margin-top: 25px;
}
.margin-top-thirty {
  margin-top: 30px;
}
.margin-top-fourty {
  margin-top: 40px;
}
.margin-top-fifty {
  margin-top: 50px;
}
.margin-top-sixty {
  margin-top: 60px;
}
.margin-top-seventy {
  margin-top: 70px;
}
.margin-top-eighty {
  margin-top: 80px;
}
.margin-bottom-zero {
  margin-bottom: 0px;
}
.margin-bottom-two {
  margin-bottom: 2px;
}
.margin-bottom-five {
  margin-bottom: 5px;
}
.margin-bottom-ten {
  margin-bottom: 10px;
}
.margin-bottom-twenty {
  margin-bottom: 20px;
}
.margin-bottom-thirty {
  margin-bottom: 30px;
}
.margin-bottom-fourty {
  margin-bottom: 40px;
}
.margin-right-zero {
  margin-right: 0px;
}
.margin-right-five {
  margin-right: 5px;
}
.margin-right-ten {
  margin-right: 10px;
}
.margin-right-eight {
  margin-right: 8px;
}
.margin-right-twenty {
  margin-right: 20px;
}
.margin-right-thirty {
  margin-right: 30px;
}
.margin-left-zero {
  margin-left: 0px;
}
.margin-left-five {
  margin-left: 5px;
}
.margin-left-eight {
  margin-left: 8px;
}
.margin-left-ten {
  margin-left: 10px;
}
.margin-left-twenty {
  margin-left: 20px;
}
.margin-left-twentyfive {
  margin-left: 25px;
}
.margin-left-thirty {
  margin-left: 30px;
}
.margin-lr-ten {
  margin: 0 10px;
}
.margin-lr-fifteen {
  margin: 0 15px;
}
.margin-lr-twenty {
  margin: 0 20px;
}
.margin-tb-ten {
  margin: 10px 0;
}
.margin-lr-five {
  margin: 0 5px;
}
.margin-five {
  margin: 5px;
}
.margin-death {
  margin: 0px;
}
.pad-zero {
  padding: 0px;
}
.pad-five {
  padding: 5px;
}
.pad-lr-five {
  padding: 0 5px;
}
.pad-left-zero {
  padding-left: 0px;
}
.pad-left-five {
  padding-left: 5px;
}
.pad-left-four {
  padding-left: 4px;
}
.pad-left-ten {
  padding-left: 10px;
}
.pad-left-fifteen {
  padding-left: 15px;
}
.pad-left-twenty {
  padding-left: 20px;
}
.pad-left-thirty {
  padding-left: 30px;
}
.pad-left-fourty {
  padding-left: 40px;
}
.pad-right-zero {
  padding-right: 0px;
}
.pad-right-five {
  padding-right: 5px;
}
.pad-right-ten {
  padding-right: 10px;
}
.pad-right-fifteen {
  padding-right: 15px;
}
.pad-right-twenty {
  padding-right: 20px;
}
.pad-right-thirty {
  padding-right: 30px;
}
.pad-ten {
  padding: 10px;
}
.pad-fifteen {
  padding: 15px;
}
.pad-twenty {
  padding: 20px;
}
.pad-thirty {
  padding: 30px;
}
.pad-tb-five {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pad-tb-ten {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pad-lr-ten {
  padding-left: 10px;
  padding-right: 10px;
}
.pad-lr-fifteen {
  padding-left: 15px;
  padding-right: 15px;
}
.pad-lr-twenty {
  padding-left: 20px;
  padding-right: 20px;
}
.pad-lr-thirty {
  padding-left: 30px;
  padding-right: 30px;
}
.pad-top-two {
  padding-top: 2px;
}
.pad-top-five {
  padding-top: 5px;
}
.pad-top-ten {
  padding-top: 10px;
}
.pad-top-fifteen {
  padding-top: 15px;
}
.pad-top-twenty {
  padding-top: 20px;
}
.pad-top-thirty {
  padding-top: 30px;
}
.pad-bottom-two {
  padding-bottom: 2px;
}
.pad-bottom-five {
  padding-bottom: 5px;
}
.pad-bottom-ten {
  padding-bottom: 10px;
}
.pad-bottom-fifteen {
  padding-bottom: 15px;
}
.pad-bottom-twenty {
  padding-bottom: 20px;
}
.pad-bottom-thirty {
  padding-bottom: 30px;
}
.pad-bottom-fourty {
  padding-bottom: 40px;
}
.pad-bottom-fifty {
  padding-bottom: 50px;
}
.pad-bottom-fifty-eight {
  padding-bottom: 58px;
}
.pad-bottom-seventy {
  padding-bottom: 70px;
}
.padding-death {
  padding: 0px !important;
}
.full-height {
  height: 100%;
}
.full-width {
  width: 100%;
}
.auto-width {
  width: auto !important;
}
.centerItems {
  justify-content: center;
}
.flex-row {
  flex-flow: row;
}
.flex-col {
  flex-flow: column;
}
.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.absolute-center {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    transform: translateY(-60%);
}

.center-center.column {
  flex-direction: column;
}
.justify-start {
  justify-content: flex-start;
}
.center-margin {
  margin: 0 auto;
}
.left-align {
  align-items: flex-end;
}
.valign {
  display: flex;
  align-items: center;
}
.flex-center {
  justify-content: center;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-space-between {
  justify-content: space-between;
}
.flex-v-spacer {
  flex: 1;
}
.flex-between {
  justify-content: space-between;
}
.flex-0 {
  flex: 0;
}
.flex-1-5 {
  flex: 1.5;
}
.flex-2 {
  flex: 2;
}
.line-height-1 {
  line-height: 1;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-headline {
  color: var(--ion-text-color);
}
.text-muted {
  color: var(--ion-color-medium-shade);
}
.text-xsmall {
  font-size: 9px;
  font-weight: 500;
}
.text-small {
  font-size: 11px;
  font-weight: 500;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-13 {
  font-size: 13px;
}

.text-title {
  font-size: 14px;
}
.text-white {
  color: #fff;
}
.text-black {
  color: #000000 !important;
}
.text-secondary {
  color: var(--ion-color-medium-shade);
}
.text-normal {
  font-weight: normal;
}
.text-bold {
  font-weight: bold;
}
.text-semibold {
  font-weight: 600;
}
.text-thin {
  font-weight: 100;
}
.fw-5 {
  font-weight: 500;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-error {
  color: var(--ion-color-danger-shade);
}
.text-hover:hover {
  color: var(--ion-color-primary);
}
.text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.search-match {
  background-color: #fdedbf;
}
.display-block {
  display: block;
}
.display-flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.display-inline-block {
  display: inline-block;
}
.flex-end {
  align-self: flex-end;
}
.large-text {
  font-size: 2em;
}
.hide,
.hidden {
  display: none;
}
.invisible {
  visibility: hidden;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.spacer {
  height: 30px;
}
.bg-white {
  background-color: #fff;
}
.position-absolute {
  position: absolute;
  z-index: 1000;
}
.close-modal {
  top: -10px;
  right: 2px;
  font-weight: bold;
  color: #255eb8;
  font-size: 25px;
}
.loading-overlay {
  background-color: rgba(208, 208, 208, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: -2px;
  z-index: 4;
}

.fade-in {
  animation: fadeIn ease 5s;
  -webkit-animation: fadeIn ease 5s;
  -moz-animation: fadeIn ease 5s;
  -o-animation: fadeIn ease 5s;
  -ms-animation: fadeIn ease 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut ease 5s;
  -webkit-animation: fadeOut ease 5s;
  -moz-animation: fadeOut ease 5s;
  -o-animation: fadeOut ease 5s;
  -ms-animation: fadeOut ease 5s;
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.border-none {
  border: 0px none;
}
.bordered-text-input {
  border: 1px solid #dadada;
  background-color: #fff;
}
.border-lr-muted {
  border-left: 1px solid #dadada;
  border-right: 1px solid #dadada;
}
hr {
  background-color: #dadada;
  margin-top: 10px;
  margin-bottom: 20px;
}

.spinner-small {
  width: 20px;
  height: 20px;
}
.spinner-med {
  width: 30px;
  height: 30px;
}
.spinner-large {
  width: 50px;
  height: 50px;
}
.spinner-xlarge {
  width: 70px;
  height: 70px;
}

.bottom-divider {
  border-bottom: 2px solid #e2e2e2;
}
.bottom-divider-thin {
  border-bottom: 1px solid #e2e2e2;
}
.bottom-divider-thinnest {
  border-bottom: 1px solid rgb(226 226 226 / 45%);
}
.bottom-divider-thin-transparent {
  border-bottom: 1px solid rgb(226 226 226 / 19%);
}
.top-divider {
  border-top: 2px solid #e2e2e2;
}
.top-divider-thin {
  border-top: 1px solid #e2e2e2;
}
.right-divider {
  border-right: 2px solid #e2e2e2;
}
.left-divider {
  border-left: 2px solid #e2e2e2;
}
.top-divider-v2 {
  border-top: 2px solid var(--ion-color-light);
}
.bottom-divider-v2 {
  border-bottom: 2px solid var(--ion-color-light);
}
.icon-xsmall {
  width: 17px;
}
.icon-small {
  width: 24px;
}
.icon-medium {
  width: 28px;
  height: 28px;
}
.icon-large {
  width: 32px;
  height: 32px;
}
.icon-xlarge {
  width: 50px;
  height: 50px;
}
.icon-grey {
  font-size: 20px;
  fill: var(--ion-color-medium-shade);
}
.icon-black {
  fill: #000;
}
.icon-primary {
  fill: var(--ion-color-primary);
}
.text-grey {
  color: var(--ion-color-medium-shade);
}
.text-charcoal {
  color: #7e7e7e;
}
.text-blue {
  color: var(--ion-color-primary);
}
.text-med {
  font-size: 16px;
}
.text-large {
  font-size: 20px;
}
.icon-grey {
  fill: #bbbec3;
}
.icon-dark-grey {
  fill: #7c848e;
}
.icon-blue {
  fill: #0f5db8;
}
.icon-red {
  fill: #e02020;
}
.icon-orange {
  fill: #f6b500;
}
.overflow-y-scroll {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.prime-view-container {
  display: flex;
  flex-direction: column;
}

@media (max-width: 767px) {
  .prime-view-container.fixed-full-height {
    height: calc(100vh - 30px);
    overflow: scroll;
  }
}

.prime-view-container.prime-left-menu {
  padding-left: 300px;
  position: relative;
  overflow: hidden;
}

.prime-full-width-container {
  margin: 0 min(max(1px, calc(0.0625rem + ((1vw - 0.01px) * 2.0323))), 40px) 30px;

  /* flex-grow: 1;
  display: flex;
  flex-direction: column; */
}

.prime-boxed-container {
  display: flex;
  justify-content: center;
  padding: 60px 20px;
}

.prime-boxed-container > div {
  width: 660px;
}

.coming-soon-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #b8b8b8d4;
  z-index: 10000;
}

.notifications-well {
  border: 1px solid #c1daf5;
  background-color: #dee9f2;
  border-radius: 8px;
  padding: 10px;
  color: #0c5baf;
}

.danger-well {
  border: 1px solid #f5c1c1;
  background-color: #f2dede;
  color: #a51515;
}

.grey-well {
  border: 1px solid #c6c6c6;
  background-color: #d7d7d7;
  border-radius: 8px;
  color: #3e3e3e;
  height: 40px;
}

.bg-grey-one {
  background-color: #e6e6e6;
}
.bg-grey-two {
  background-color: #f6f6f6;
}
.bg-grey-three {
  background-color: #f8f8fb;
}
.bg-primary {
  background-color: #0f5cb8;
}
.bg-white {
  background-color: #ffffff;
}

/* animations */

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes bounceIn {
  0%,
  20%,
  40%,
  60%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.fade-in {
  animation: fadeIn ease-in-out 0.3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Ionic Global Variables */
:root {
  --ion-font-family: "IBM Plex Sans", Helvetica, Arial, sans-serif;
  --ion-background-color: #f8f8fb;
  --ion-background-color-rgb: 255, 255, 255;
  --ion-background-secondary-color: #f3f3f6;
  --ion-text-color: #0d1c2e;
  --ion-text-color-rgb: 13, 28, 46;
  --ion-color-primary: #0f5db8;
  --ion-color-primary-rgb: 15, 93, 184;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #0d52a2;
  --ion-color-primary-tint: #276dbf;
  --ion-color-button-bg-primary: #e8edf5;
  --ion-item-background: none;
  --ion-grid-padding: 0px;
  --ion-grid-column-padding: 0px;
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e6e6e6;
  --ion-color-white-tint: #ffffff;
  --ion-color-light: #f2f3f5;
  --ion-color-light-rgb: 242, 243, 245;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d5d6d8;
  --ion-color-light-tint: #fafafa;
  --ion-color-medium: #919ba3;
  --ion-color-medium-rgb: 145, 155, 163;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #80888f;
  --ion-color-medium-tint: #9ca5ac;
  --ion-color-dark: #5f5f5f;
  --ion-color-dark-rgb: 95, 95, 95;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #545454;
  --ion-color-dark-tint: #6f6f6f;
  --ion-color-success: #73b72b;
  --ion-color-success-rgb: 115, 183, 43;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #65a126;
  --ion-color-success-tint: #81be40;
  --ion-color-warning: #f7b500;
  --ion-color-warning-rgb: 247, 181, 0;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d99f00;
  --ion-color-warning-tint: #f8bc1a;
  --ion-color-danger: #e02020;
  --ion-color-danger-rgb: 224, 32, 32;
  --ion-color-danger-contrast: rgb(255, 205, 205);
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c51c1c;
  --ion-color-danger-tint: #e33636;
  --color-secondary-background: #e9edf4;
  --color-secondary-background-shade: #e3e7ef;
  --color-secondary-button: #e9edf4;
  --color-secondary-button-shade: #e3e7ef;
  --ion-modal-secondary-background: var(--ion-background-color);

  --prime-card-border-radius: 10px;
  --prime-card-shadow: 0 5px 20px 0px rgba(46, 61, 73, 0.1);
  --prime-button-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0.3);
  --prime-button-shadow-hover: 0 5px 14px 0px rgba(15, 93, 184, 0.5);
  --prime-button-small-shadow: 0 1px 5px 0px rgba(15, 93, 184, 0.15);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

ion-card {
  padding: 5px;
  border-radius: var(--prime-card-border-radius) !important;
  box-shadow: var(--prime-card-shadow) !important;
  margin-top: 10px !important;
  /* margin-left: 20px !important;
  margin-right: 20px !important; */
}

ion-card.no-shadow {
  box-shadow: none !important;
}

ion-card.compact-shadow {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1), 0px 1px 3px 0px rgba(0, 0, 0, 0.08) !important;
}

ion-card.prime-card-info {
  margin: 0 0 10px 0 !important;
  box-shadow: none !important;
  background: var(--ion-color-light);
  --color: var(--ion-text-color);
}

ion-card.prime-card-info ion-col.divider {
  padding-left: 20px;
}
ion-card.prime-card-info ion-col.divider:before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  width: 2px;
  height: 100%;
  border-radius: 4px;
  background: var(--ion-color-light-shade);
}
ion-card.prime-patient-card {
  background: #ffffff;
  color: var(--ion-text-color);
  display: flex;
  align-items: center;
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
ion-card.prime-patient-card:hover {
  box-shadow: 0 3px 15px 0px rgba(46, 61, 73, 0.3) !important;
}
ion-card:first-child {
  margin-top: 20px;
}

ion-card-title {
  font-size: 28px;
  font-weight: 700;
}

ion-button.ion-color-light {
  --ion-color-contrast: var(--ion-color-primary) !important;
}

ion-modal {
  --ion-background-color: #ffffff;
  --max-width: 440px !important;
}
ion-modal.prime-modal-small .sc-ion-modal-ios {
  --max-width: 360px !important;
  --width: 100% !important;
  --max-height: 460px !important;
  --height: 60% !important;
}
ion-modal.prime-modal-med .sc-ion-modal-ios {
  --max-width: 495px !important;
  --width: 100% !important;
  --max-height: 650px !important;
  --height: 60% !important;
  min-height: 530px;
}
ion-modal.prime-modal-wide .sc-ion-modal-ios {
  --max-width: 760px !important;
  --width: 100% !important;
}
ion-modal.prime-modal-xwide .sc-ion-modal-ios {
  --max-width: 1000px !important;
  --width: 100% !important;
  --height: 700px !important;
}

.modal-double-wide .modal-wrapper.ios.sc-ion-modal-ios {
  min-width: 800px;
}

.prime-modal-fullscreen .modal-wrapper.sc-ion-modal-ios {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0px;
  max-width: 100% !important;
}

.prime-modal-fullscreen.darkmode > .modal-wrapper.sc-ion-modal-ios {
  background-color: rgba(0, 0, 0, 0.7);
}

/* Ionic ion-header shadow override */
.header-md:after {
  background-image: none;
}

/* -------------------------------- */
/* Global Button ------------------ */
/* -------------------------------- */
button.prime-button {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
  height: 40px;
  font-size: 12px;
  font-weight: 700;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  box-shadow: var(--prime-button-shadow);
  transition: all 0.2s ease-in-out;
}
button.prime-button:hover {
  background-color: var(--ion-color-primary-shade);
  box-shadow: var(--prime-button-shadow-hover);
}
button.prime-button.button-block {
  width: 100%;
}
button.prime-button ion-icon {
  font-size: 1.5em;
  fill: var(--ion-color-white);
  vertical-align: middle;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
button.prime-button ion-icon:last-child {
  margin-right: 0;
  /* margin-left: 5px; */
}
button.prime-button svg {
  vertical-align: middle;
  margin-right: 5px;
  margin-top: -3px;
  fill: #ffffff;
}
button.prime-button.button-solo svg {
  margin: 0px;
}

.icon-standalone {
  font-size: 25px;
  color: #767676;
}

/* Button Pending State */
button.prime-button ion-spinner {
  display: none;
  opacity: 0;
}
button.prime-button.button-pending {
  pointer-events: none;
  background-color: var(--ion-color-medium);
  box-shadow: 0 1px 3px 0px rgba(15, 93, 184, 0);
}
button.prime-button.button-pending span {
  display: none;
}
button.prime-button.button-pending ion-spinner {
  display: inline-block;
  opacity: 1;
}

/* Button Disabled State */
button.prime-button:disabled {
  background: none;
  border: 2px solid #d4d6d8;
  color: #d4d6d8;
  cursor: not-allowed;
  box-shadow: none;
}
button.prime-button.disabled {
  background: none;
  border: 2px solid #d4d6d8;
  color: #d4d6d8;
  cursor: not-allowed;
  box-shadow: none;
}

button.prime-button.disabled ion-icon {
  fill: #d4d6d8;
}
button.prime-button:disabled svg,
button.prime-button.disabled svg {
  fill: #c2c3c5;
}

/* Button Secondary */
button.prime-button.button-secondary {
  background: var(--color-secondary-button);
  color: var(--ion-color-primary);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0);
  font-size: 12px;
}
button.prime-button.button-secondary ion-icon {
  fill: var(--ion-color-primary);
}

button.prime-button.button-secondary.muted ion-icon {
  fill: var(--ion-color-dark-tint);
}
button.prime-button.button-secondary:hover {
  background: var(--color-secondary-button-shade);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0.1);
}
button.prime-button.button-secondary svg {
  fill: var(--ion-color-primary);
}

/* Button Tertiary */
button.prime-button.button-tertiary {
  background: var(--ion-color-dark);
  color: var(--ion-color-white);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0);
  font-size: 13px;
}
button.prime-button.button-tertiary ion-icon {
  fill: var(--ion-color-white);
}

button.prime-button.button-tertiary.muted ion-icon {
  fill: var(--ion-color-white-tint);
}
button.prime-button.button-tertiary:hover {
  background: var(--ion-color-dark-tint);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0.1);
}
button.prime-button.button-tertiary svg {
  fill: var(--ion-color-white);
}

/* Button Small Warning */
button.prime-button.button-small-warning {
  background: none;
  color: var(--ion-color-warning-shade);
  box-shadow: none;
  font-size: 10px;
  border: 2px solid var(--ion-color-warning);
  padding: 1px 5px;
  height: 17px;
}
button.prime-button.button-small-warning:hover {
  background: var(--ion-color-warning);
  color: var(--ion-text-color);
  box-shadow: 0 2px 8px 0px rgba(247, 181, 0, 0.5);
}
button.prime-button.button-small-warning svg {
  fill: var(--ion-color-warning);
}

/* Button group columns */
ion-row.button-group ion-col:first-child {
  padding-right: 5px !important;
}
ion-row.button-group ion-col:nth-child(2) {
  padding-left: 5px !important;
}

button.prime-button.thin {
  height: 22px;
  padding-right: 0px;
  font-size: 10px;
}

/* info icon */
.form-tooltip-icon {
  width: 20px;
  display: inline-block;
  vertical-align: bottom;
  fill: var(--ion-color-medium);
}

.form-tooltip-icon,
.small {
  width: 15px;
}

.form-tooltip-icon:hover {
  fill: var(--ion-color-medium-shade);
}

/* Button Clear */
button.prime-button.button-clear {
  background: none;
  color: var(--ion-color-primary);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0);
  font-size: 13px;
}
button.prime-button.button-clear ion-icon {
  fill: var(--ion-color-primary);
}
button.prime-button.button-clear:hover {
  background: var(--color-secondary-button);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0);
}
button.prime-button.button-clear svg {
  fill: var(--ion-color-primary);
}

/* Button Transparent */
button.prime-button.button-transparent {
  background: transparent;
  color: var(--ion-color-primary);
  font-size: 13px;
  box-shadow: none;
}
button.prime-button.button-transparent ion-icon {
  fill: var(--ion-color-primary);
}
button.prime-button.button-transparent:hover {
  background: transparent;
}
button.prime-button.button-transparent svg {
  fill: var(--ion-color-primary);
}

/* Button Icon Stack */
button.prime-button.button-icon-stack .button-inner {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-flow: row nowrap;
}
button.prime-button.button-icon-stack ion-icon {
  font-size: 1.85em;
  vertical-align: initial;
  margin: 0 0 4px 0;
  position: initial;
  top: 0;
}
button.prime-button.button-icon-stack {
  height: auto;
  font-size: 13px;
  letter-spacing: 0;
}
button.prime-button.button-icon-stack .button-inner {
  flex-flow: column;
  margin: 10px 0;
}
/* -------------------------------- */
/* Links -------------------------- */
/* -------------------------------- */
.link-grey {
  font-size: 16px;
  color: #2e2e2e;
  text-decoration: none;
}
.link-grey:hover {
  text-decoration: underline;
}

/* -------------------------------- */
/* Input Fields ------------------- */
/* -------------------------------- */
input.form-control,
select.form-control,
textarea.form-control {
  display: block;
  width: 100%;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  line-height: 1.5;
  transition: border 0.2s ease-in-out;
}
input.form-control.large-input {
  padding: 12px 20px;
  font-size: 15px;
}

input.form-control.medium-input {
  padding: 11px 15px;
  font-size: 13px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(assets/arrow-dropdown.svg) no-repeat white;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

select.form-control::-ms-expand {
  display: none;
}

select.form-control option {
  font-weight: normal;
}

.form-control-search {
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 0px 8px 15px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  line-height: 1.5;
  transition: border 0.2s ease-in-out;
}
.form-control-search.search-secondary {
  background: var(--color-secondary-button);
  border: 0 none;
  color: var(--ion-color-primary);
  box-shadow: 0 3px 8px 0px rgba(15, 93, 184, 0);
  font-size: 13px;
  font-weight: 700;
}
.form-control-search input {
  background: transparent;
  border: 0 none;
}
.form-control-search.search-secondary input::placeholder {
  font-weight: 700;
  color: var(--ion-color-primary);
}
.prime-alt-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  margin-bottom: 15px;
  -webkit-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
  padding: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(./assets/arrow-dropdown.svg) no-repeat right #fff;
  background-size: 15px;
  background-position-x: 365px;
}

input.form-control:hover,
select.form-control:hover {
  border: 2px solid var(--ion-color-medium);
}
input.form-control:focus,
select.form-control:focus {
  border: 2px solid var(--ion-color-primary-tint);
}
select.form-control {
  height: 40px;
}

input.form-control:disabled,
select.form-control:disabled {
  border: 2px solid #d4d6d8;
  cursor: not-allowed;
  background-color: rgb(225, 225, 225);
  cursor: not-allowed;
}

.form-control::placeholder {
  color: var(--ion-color-medium);
}

h6 + .prime-form-subtitle {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium);
  margin-top: 0;
  margin-bottom: 10px;
}

.prime-form-container {
  max-width: 450px;
  margin: 0px auto 0;
}

.prime-form-group {
  margin-top: 20px;
}

/* -------------------------------- */
/* Styled Checkbox ---------------- */
/* -------------------------------- */
/* Enclose checkbox input inside <div class="checkbox"></div> */
.checkbox,
.checkbox-radio {
  height: 24px;
  width: 24px;
  display: block;
  position: relative;
}
.checkbox input[type="checkbox"],
.checkbox-radio input[type="radio"] {
  opacity: 0;
}
.checkbox label,
.checkbox-radio label {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
}
.checkbox label::before,
.checkbox label::after,
.checkbox-radio label::before,
.checkbox-radio label::after {
  content: "";
  display: block;
  transition: all 0.2s ease-in-out;
}
.checkbox label::before,
.checkbox-radio label::before {
  height: 20px;
  width: 20px;
  border: 2px solid var(--ion-color-primary);
  border-radius: 28px;
  background: #ffffff;
  cursor: pointer;
}
.checkbox label::after,
.checkbox-radio label::after {
  height: 5px;
  width: 10px;
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  transform: rotate(-45deg);
  position: absolute;
  position: absolute;
  top: 7px;
  left: 6px;
}
.checkbox input[type="checkbox"] + label::after,
.checkbox-radio input[type="radio"] + label::after {
  content: none;
}
.checkbox input[type="checkbox"][disabled] + label:before,
.checkbox-radio input[type="radio"][disabled] + label:before {
  border: 2px solid var(--ion-color-medium);
}
.checkbox input[type="checkbox"]:checked + label::after,
.checkbox-radio input[type="radio"]:checked + label::after {
  content: "";
}
.checkbox input[type="checkbox"]:checked + label:before,
.checkbox-radio input[type="radio"]:checked + label:before {
  background: var(--ion-color-primary);
}
.checkbox input[type="checkbox"]:hover + label::before,
.checkbox-radio input[type="radio"]:hover + label::before {
  box-shadow: 0 3px 10px 0px rgba(145, 169, 197, 0.6);
}

/* -------------------------------- */
/* Ion-Select --------------------- */
/* -------------------------------- */
.prime-ion-select {
  width: 100%;
}
.prime-ion-select ion-select {
  width: 100%;
  text-align: left;
  background: #ffffff;
  border: 2px solid var(--ion-color-light-shade);
  border-radius: 8px;
  padding: 8px 15px 7px;
  font-size: 14px;
  font-family: var(--ion-font-family);
  font-weight: 500;
  color: var(--ion-text-color);
  line-height: 1.5;
  margin-bottom: 15px;
  transition: border 0.2s ease-in-out;
}
.prime-ion-select ion-select:hover {
  border: 2px solid var(--ion-color-medium);
}

/* -------------------------------- */
/* Ion-Segment -------------------- */
/* -------------------------------- */

.prime-channel-tabs-container {
  background: var(--color-secondary-background);
  border-radius: 8px;
}
.prime-channel-tabs-container ion-segment-button {
  height: 36px;
  border-radius: 8px;
  margin: 2px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  fill: var(--ion-color-primary);
  transition: background 0.2s ease-in-out;
  --indicator-color-checked: transparent;
}
.prime-channel-tabs-container ion-segment-button svg {
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked {
  box-shadow: var(--prime-button-small-shadow);
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked svg {
  fill: #ffffff;
}

.prime-channel-tabs-container ion-segment-button .active {
  content: "";
  background: var(--ion-color-primary);
  border-radius: 10px;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.prime-channel-tabs-container ion-segment-button.segment-button-checked .active {
  background: var(--ion-color-white);
}

.prime-channel-tabs-container.ion-segment-button-small ion-segment-button {
  height: 26px;
}

.tabs-divider {
  border-right: 2px solid #dce1e9;
  margin: 8px 0;
}

/* -------------------------------- */
/* Form Block Info ---------------- */
/* -------------------------------- */
/* Usage -------------------------- */
/* <div class="prime-form-block-container">                     */
/*   <div class="prime-form-block-title-row">                   */
/*     <div class="prime-form-block-title">                     */
/*       <h6>Title</h6>                                         */
/*     </div>                                                   */
/*     <div class="prime-form-block-input">                     */
/*       <span>Yes</span>                                       */
/*       <ion-toggle color="primary" mode="ios"></ion-toggle>   */
/*     </div>                                                   */
/*   </div>                                                     */
/*   <div class="prime-form-block-description">                 */
/*     Description                                              */
/*   </div>                                                     */
/* </div>                                                       */

.prime-form-block-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.prime-form-block-container .prime-form-block-title-row {
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 992px) {
  .prime-form-block-container .prime-form-block-title-row:not(.keep-input-inline) {
    flex-direction: column;
  }
}

.sc-ion-segment-ios-h {
  --color-checked: var(--ion-color-primary-contrast, #fff);
}

.sc-ion-segment-ios-h .segment-button-checked.tab-start {
  --background-checked: var(--ion-color-success, #3880ff);
}

.sc-ion-segment-ios-h .segment-button-checked.tab-end {
  --background-checked: var(--ion-color-danger, #3880ff);
}

.prime-form-block-container .prime-form-block-title {
  flex-grow: 1;
}
.prime-form-block-container .prime-form-block-title h6 {
  margin: 12px 0;
}
.prime-form-block-container .prime-form-block-input {
  display: flex;
  align-items: center;
}
.prime-form-block-container .prime-form-block-input input {
  margin-bottom: 0;
}
.prime-form-block-container .prime-form-block-input.input-group {
  min-width: 320px;
}
.prime-form-block-container .prime-form-block-input.input-group input,
.prime-form-block-container .prime-form-block-input.input-group select {
  margin-right: 5px;
}
.prime-form-block-container .prime-form-block-input.input-group input:last-child,
.prime-form-block-container .prime-form-block-input.input-group select:last-child {
  margin-right: 0;
}
.prime-form-block-container .prime-form-block-input.width-320 {
  width: 320px;
}
.prime-form-block-container .prime-form-block-input.width-250 {
  width: 250px;
}
.prime-form-block-container .prime-form-block-input.width-150 {
  width: 150px;
}
.prime-form-block-description {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--ion-color-medium-shade);
  margin-top: 2px;
  max-width: 380px;
}
.prime-form-block-container .prime-form-block-description.full {
  max-width: 100%;
  width: 100%;
}
.prime-form-block-container .prime-form-block-inline-input {
  text-align: right;
}
.prime-form-block-container .prime-form-block-inline-input input,
.prime-form-block-container .prime-form-block-inline-input select {
  display: inline-block;
  width: 100px;
  margin: 0 5px 10px;
}

.prime-form-block-container .prime-form-block-subcontainer {
  margin-left: 30px;
  font-size: 12px;
  font-weight: 700;
}
.prime-form-block-container .prime-form-block-subcontainer .v-select {
  display: inline-block;
  margin: 0 10px;
}

/* -------------------------------- */
/* Info Sections ------------------ */
/* -------------------------------- */
.info-container {
  margin-bottom: 10px;
}
.info-label {
  font-size: 10px;
  color: var(--ion-color-medium-shade);
}
.info-text {
  font-size: 14px;
  font-weight: 700;
}

/* -------------------------------- */
/* Modals ------------------------- */
/* -------------------------------- */
ion-modal {
  --ion-background-color: #ffffff;
  --max-width: 495px !important;
}
ion-modal ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
}
.prime-modal {
  color: var(--ion-text-color);
}
.prime-modal ion-header ion-toolbar {
  padding: 5px 0px 0px 20px;
}
.prime-modal ion-header ion-toolbar svg {
  margin-right: 10px;
}
.prime-modal ion-header ion-toolbar h5 {
  margin: 0;
  font-size: 18px;
  font-weight: 700;
}
.prime-modal .prime-modal-content {
  padding: 0 20px;
  border-bottom: 2px solid var(--ion-color-light-shade);
}
.prime-modal .prime-modal-content h6 {
  margin: 10px 0 10px;
}
/* Emoji popover height */
.popover-emoji .popover-content {
  max-height: 230px;
}

.popover-sort-convos .popover-content {
  left: 133px !important;
  top: 123px !important;
  max-width: 270px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.popover-sort-convos ion-radio[slot="start"] {
  margin: 9px 12px 9px 2px !important;
}
.popover-sort-convos .popover-arrow {
  top: 113px !important;
}
.popover-sort-convos ion-label {
  color: #000000ee !important;
}

@media only screen and (min-height: 600px) and (max-height: 767px) and (min-width: 768px) {
  .sc-ion-modal-ios-h {
    --height: 575px;
  }
}

.input-wrapper {
  justify-content: space-between;
}
/* -------------------------------- */
/* Popover ------------------------ */
/* -------------------------------- */
/* ion-popover.ios ion-backdrop {
  opacity: 0.5 !important;
} */
ion-popover.ios,
ion-popover.md {
  --ion-background-color: #ffffff;
  --width: 300px !important;
}
ion-popover.ios.popover-width-lg,
ion-popover.md.popover-width-lg {
  --width: 440px !important;
}
ion-popover.ios.popover-width-md,
ion-popover.md.popover-width-md {
  --width: 300px !important;
}
ion-popover.ios.popover-width-auto,
ion-popover.md.popover-width-auto {
  --width: auto !important;
}
ion-popover.ios ion-content,
ion-popover.md ion-content {
  --padding-start: 20px;
  --padding-end: 20px;
  --padding-bottom: 20px;
}

ion-popover.ios.shadow .popover-content {
  -webkit-box-shadow: 0px 0px 20px -9px #000000;
  box-shadow: 0px 0px 20px -9px #000000;
}

ion-popover.ios .popover-arrow {
  display: none;
}

.prime-popover h6 {
  margin-top: 0;
}

/* -------------------------------- */
/* Error Notification ------------- */
/* -------------------------------- */
.error-notification {
  color: var(--ion-color-danger);
  font-weight: 500;
  background: var(--ion-color-danger-contrast);
  padding: 15px 20px;
  display: flex;
  text-align: left;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}
.error-notification svg {
  fill: var(--ion-color-danger);
  width: 32px;
  margin-right: 10px;
}

/* -------------------------------- */
/* Tooltips ----------------------- */
/* -------------------------------- */
.tooltip {
  display: block !important;
  z-index: 10000;
  font-size: 11.5px;
  max-width: 650px;
}

.custom-tooltip {
  max-width: 350px;
}

.tooltip .tooltip-inner ul {
  margin: 0;
  list-style: none !important;
}

.tooltip .tooltip-inner {
  background-color: #0d1c2e;
  color: white;
  font-weight: 600;
  border-radius: 10px;
  padding: 8px 12px;
}

.tooltip .tooltip-inner hr {
  background: #ffffff47;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.tooltip.max-width-300 {
  max-width: 300px;
}

/* -------------------------------- */
/* Datepicker --------------------- */
/* -------------------------------- */
.prime-v-date-picker-container {
  position: relative;
  width: 100%;
  height: 40px;
}
.prime-v-date-picker-container ion-icon {
  position: absolute;
  font-size: 24px;
  right: 7px;
  top: 8px;
  color: var(--ion-color-medium);
}
.prime-v-date-picker {
  width: 100%;
}

/* -------------------------------- */
/* Avatars ------------------------ */
/* -------------------------------- */
.avatar {
  color: #fff;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-self: center;
  border-radius: 50%;
  text-transform: uppercase;
  background-color: #6faf8e;
  z-index: 10;
  white-space: nowrap;
}
.current-patient-avatar {
  background-color: #6faf8e;
}
.current-patient-avatar-main {
  min-width: 40px;
  min-height: 40px;
  font-size: 16px;
  font-weight: 500;
}

.logged-in-user .avatar {
  background-color: var(--ion-color-medium);
}

/* -------------------------------- */
/* Slides -----------------------_- */
/* -------------------------------- */
.swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  opacity: 0.4;
}

/* table row pill badges */
.pill {
  display: inline-block;
  width: 100%;
  border-radius: 100px;
  padding: 6px 10px;
  background-color: #e6e6e6;
  color: #656e78;
  font-size: 13px;
}
.pill-sm {
  display: inline-block;
  border-radius: 75rem;
  padding: 4px 9px;
  font-size: 10px;
  font-weight: bold;
  line-height: 109%;
}
.pill-mm {
  display: inline-block;
  border-radius: 75rem;
  padding: 3px 8px;
  font-size: 10.5px;
  font-weight: bold;
  line-height: 109%;
}
.pill-patient {
  border-radius: 40px;
}
.pill-blue {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-white);
}
.pill-gray {
  background-color: var(--ion-color-secondary);
  color: #3880ff;
}
.pill-gray-dark {
  background-color: #e6e6e6;
  line-height: 80%;
}
.pill-wrap {
  border-radius: 100px;
}

.pill-fade-blue {
  background: var(--color-secondary-button);
  color: var(--ion-color-primary);
}

.pill-fade-gray {
  background: var(--color-secondary-button);
  color: #919ba3;
}

.pill-auto-save {
  font-size: 9px;
  padding: 5px 10px;
}

/* .pill-wrap-green {
  background-color: #e4efd9;
} */

.pill-wrap-green .pill {
  background-color: #70c01c;
  color: #fff;
}

/* .pill-wrap-yellow {
  background-color: #fff3d5;
} */

.pill-wrap-yellow .pill {
  background-color: rgba(var(--ion-color-warning-rgb), 0.8);
  color: var(--ion-text-color);
}

/* .pill-wrap-orange {
  background-color: #ffe1cb;
} */

.pill-wrap-orange .pill {
  background-color: #f3761c;
  color: #fff;
}

/* .pill-wrap-red {
  background-color: #fce8e8;
} */

.pill-wrap-red .pill {
  background-color: #ec7878;
  color: var(--ion-text-color);
}

.pill-wrap-urgent {
  background-color: #fce8e8;
}

.pill-wrap-urgent .pill {
  background-color: #e02020;
  color: #fff;
}

.blue-pill {
  background-color: #d0dff1;
  color: #2f66aa;
}
.yellow-pill {
  background-color: rgba(var(--ion-color-warning-rgb), 0.8);
  color: var(--ion-text-color);
}
.orange-pill {
  background-color: #f3761c;
  color: #fff;
}
.red-pill {
  background-color: var(--ion-color-danger);
  color: var(--ion-color-white);
}
.green-pill-two {
  background-color: #70c01c;
  color: #fff;
}
.urgent-pill {
  background-color: #e02020;
  color: #fff;
}
.green-pill {
  background-color: #a1da65;
  color: var(--ion-text-color);
}
/* -------------------------------- */
/* Patient Roster - Table --------- */
/* -------------------------------- */

.prime-table th,
.prime-table td {
  text-align: left;
}

.prime-table th.text-center,
.prime-table td.text-center {
  text-align: center;
}

.prime-table th,
.prime-tasks-table th {
  cursor: pointer;
}

.prime-table td {
  font-weight: 400;
}

.prime-table thead th,
.prime-tasks-table thead th {
  opacity: 0.4;
  transition: opacity 0.2s ease-in-out;
}

.prime-table thead th:hover,
.prime-tasks-table thead th:hover {
  opacity: 0.35;
}

.prime-table th.sorting,
.prime-tasks-table th.sorting {
  cursor: pointer;
}

.prime-table thead th.sorting-asc,
.prime-tasks-table thead th.sorting-asc,
.prime-table thead th.sorting-asc:hover,
.prime-tasks-table thead th.sorting-asc:hover,
.prime-table thead th.sorting-desc,
.prime-tasks-table thead th.sorting-desc,
.prime-table thead th.sorting-desc:hover,
.prime-tasks-table thead th.sorting-desc:hover {
  opacity: 1;
}

.prime-table td {
  padding: 10px 12px 10px 5px;
  vertical-align: middle;
  border-top: 2px solid var(--ion-color-light);
}

.prime-tasks-table td {
  text-align: left;
  padding: 10px clamp(1px, 1px, 12px) 10px 5px;
  vertical-align: middle;
  border-top: 0 none;
}

.prime-table td.bold {
  font-weight: 500;
}

.prime-table tr td:first-child,
.prime-table tr th:first-child {
  padding-left: 30px;
}

.prime-table tr td:last-child,
.prime-table tr th:last-child {
  padding-right: 10px;
}

.prime-table th:after,
.prime-tasks-table th:after {
  content: url(assets/sort.svg);
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 3px;
  height: 18px;
  width: 18px;
  opacity: 0.5;
}

.prime-table th.prime-roster-nosort:after,
.prime-tasks-table th.prime-roster-nosort:after {
  content: "";
  display: none;
}
.prime-table th.prime-roster-nosort,
.prime-tasks-table th.prime-roster-nosort {
  cursor: default;
}

.prime-table th.sorting:after,
.prime-tasks-table th.sorting:after {
  content: url(assets/arrow-down.svg);
  display: inline-block;
  vertical-align: text-bottom;
  height: 20px;
  width: 20px;
  transition: transform 0.2s ease-in-out;
}

.prime-table thead th.sorting-asc:after,
.prime-tasks-table thead th.sorting-asc:after {
  transform: rotate(180deg);
}

.prime-table thead th.sorting-desc:after,
.prime-taks-table thead th.sorting-desc:after {
  transform: rotate(0deg);
}

.prime-table-left-align,
.vgt-left-align {
  text-align: left;
}

.prime-table-right-align,
.vgt-right-align {
  text-align: right;
}

.prime-table-center-align {
  text-align: center;
}

.prime-table-inline-icon {
  width: 20px;
  display: inline-block;
  vertical-align: middle;
}

.prime-table-hover-icon {
  fill: var(--ion-color-medium);
}

.prime-table-hover-icon:hover {
  fill: var(--ion-color-medium-shade);
}

.prime-table-small-date {
  font-size: 12px;
  font-weight: 500;
}

/* -------------------------------- */
/* Vue Good Table - Base Styles --- */
/* -------------------------------- */

.vgt-pull-left {
  float: left !important;
}

.vgt-pull-right {
  float: right !important;
}
.vgt-clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.vgt-responsive {
  width: 100%;
  overflow-x: auto;
  position: relative;
}

.vgt-text-disabled {
  color: #909399;
}

.vgt-wrap {
  position: relative;
}

.vgt-fixed-header {
  position: absolute;
  z-index: 10;
  width: 100%;
  overflow-x: auto;
}

table.vgt-table {
  font-size: 14px;
  border-collapse: collapse;
  background-color: #fff;
  width: 100%;
  max-width: 100%;
  table-layout: auto;
}

table.vgt-table tr.clickable {
  cursor: pointer;
}

table.vgt-table tr.clickable:hover {
  background-color: var(--ion-color-light);
}

.vgt-table th {
  padding: 10px 12px 10px 5px;
  vertical-align: middle;
  position: relative;
}

.vgt-table th.line-numbers,
.vgt-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-table thead th,
.vgt-tasks-table th {
  vertical-align: bottom;
  border-bottom: 1px solid #dcdfe6;
  user-select: none;
  font-size: 13px;
}

.vgt-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

/* tasks table must be styled here for some reason, not in scoped component CSS - ww */
table.vgt-tasks-table {
  font-size: 12px;
  font-weight: 500;
  background-color: rgba(255, 255, 255, 0);
  width: 100%;
  max-width: 100%;
  table-layout: auto;
  border-collapse: separate;
  border-spacing: 0 5px;
  padding: 0.938rem;
}

table.vgt-tasks-table tr.clickable {
  cursor: pointer;
}

table.vgt-tasks-table tr.clickable:hover {
  background-color: var(--ion-color-light);
}

.vgt-tasks-table th {
  padding: 10px 5px;
  vertical-align: middle;
  position: relative;
}

.vgt-tasks-table th.line-numbers,
.vgt-tasks-table th.vgt-checkbox-col {
  padding: 0 0.75em 0 0.75em;
  color: #606266;
  border-right: 1px solid #dcdfe6;
  word-wrap: break-word;
  width: 25px;
  text-align: center;
  background: linear-gradient(#f4f5f8, #f1f3f6);
}

.vgt-tasks-table th.filter-th {
  padding: 0.75em 0.75em 0.75em 0.75em;
}

.vgt-tasks-table th.vgt-row-header {
  border-bottom: 2px solid #dcdfe6;
  border-top: 2px solid #dcdfe6;
  background-color: #fafafb;
}

.vgt-tasks-table thead th {
  vertical-align: bottom;
  border-bottom: 0 none;
  user-select: none;
  font-size: 13px;
}

.vgt-tasks-table thead th.vgt-checkbox-col {
  vertical-align: middle;
}

.vgt-tasks-table tbody tr {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: var(--prime-card-shadow);
}

.vgt-tasks-table tbody tr td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-left: 20px;
}

.vgt-tasks-table tbody tr td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  padding-right: 20px;
}

.vgt-wrap__footer {
  color: var(--ion-color-medium-shade);
  padding: 20px;
  background: var(--ion-color-light-tint);
}

.prime-tasks-section-container .vgt-wrap__footer {
  color: var(--ion-color-medium-shade);
  padding: 20px;
  background: transparent;
}

.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__row-count__select {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__row-count__label {
  font-size: 14px;
  color: #909399;
}

.vgt-wrap__footer .footer__row-count__select {
  background-color: transparent;
  width: auto;
  padding: 0;
  border: 0;
  border-radius: 0;
  height: auto;
  font-size: 14px;
  margin-left: 8px;
  color: var(--ion-color-medium-shade);
  font-weight: 700;
}

.vgt-wrap__footer .footer__row-count__select:focus {
  outline: 0;
  border-color: var(--ion-color-primary);
}

.vgt-wrap__footer .footer__navigation {
  font-size: 14px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-btn,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  vertical-align: middle;
}

.vgt-wrap__footer .footer__navigation__page-btn {
  text-decoration: none;
  color: var(--ion-color-dark);
  font-weight: 700;
  white-space: nowrap;
}

.vgt-wrap__footer .footer__navigation__page-btn:focus {
  outline: 0;
  border: 0;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover {
  opacity: 0.5;
  cursor: not-allowed;
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.left:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.left:after {
  border-right-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn.disabled .chevron.right:after,
.vgt-wrap__footer .footer__navigation__page-btn.disabled:hover .chevron.right:after {
  border-left-color: var(--ion-color-dark);
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron {
  width: 24px;
  height: 24px;
  border-radius: 15%;
  position: relative;
  margin: 0 8px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron:after {
  content: "";
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.left::after {
  border-right: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__page-btn .chevron.right::after {
  border-left: 6px solid var(--ion-color-primary);
  margin-left: -3px;
}

.vgt-wrap__footer .footer__navigation__info,
.vgt-wrap__footer .footer__navigation__page-info {
  display: inline-block;
  color: #909399;
  margin: 0 16px;
}

.vgt-wrap__footer .footer__navigation__page-info__current-entry {
  width: 30px;
  text-align: center;
  display: inline-block;
  margin: 0 10px;
  font-weight: 700;
}

@media only screen and (max-width: 750px) {
  .vgt-wrap__footer .footer__navigation__info {
    display: none;
  }
  .vgt-wrap__footer .footer__navigation__page-btn {
    margin-left: 16px;
  }
}

/* -------------------------------- */
/* Skeleton Table ----------------- */
/* -------------------------------- */

.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}

.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}

/* -------------------------------- */
/* Patient Roster ----------------- */
/* -------------------------------- */

.prime-roster-options-button-container {
  height: 60px;
}
.prime-roster-container ion-card {
  margin: 20px 0 !important;
  padding: 0;
}
.prime-roster-container ion-card ion-card-header {
  padding: 20px 20px 10px 20px;
}

.split-pane-md.split-pane-visible > .split-pane-side {
  border: none;
  min-width: 80px;
  max-width: 80px;
}
.prime-main-menu-logo-container {
  width: 60px;
  margin: 10px;
  padding: 10px;
  background-color: #0b478e;
  border-radius: 10px;
}
.prime-main-menu-logo-container .prime-main-menu-logomark {
  height: 40px;
  margin-left: 1px;
}

.prime-main-menu-logotype {
  margin-left: 10px;
  margin-right: 10px;
}
.prime-main-menu {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background: var(--ion-color-primary);
}
.prime-main-menu ion-list {
  flex-grow: 1;
}
.prime-main-menu .prime-main-menu-item {
  display: block;
  width: 50px;
  margin: 15px;
  text-align: center;
  background: rgba(11, 71, 142, 0.001);
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: background 0.2s ease-in-out;
}
.prime-main-menu .prime-main-menu-item.has-counter {
  padding-bottom: 6px;
}
.prime-main-menu a {
  text-decoration: none;
}
.prime-main-menu a svg {
  fill: var(--ion-color-white);
  opacity: 0.5;
  display: block;
  margin: 0 auto;
  transition: opacity 0.2s ease-in-out;
}
.prime-main-menu .prime-main-menu-item-count {
  display: inline-block;
  font-size: 10px;
  font-weight: 700;
  background: var(--ion-color-white);
  border-radius: 20px;
  padding: 0 8px;
  margin: 3px auto 0;
  opacity: 0.5;
}
.prime-main-menu a.router-link-active ion-label {
  color: var(--ion-color-medium-contrast);
}
.prime-main-menu a.router-link-active .prime-main-menu-item {
  background: rgba(11, 71, 142, 0.5);
}
.prime-main-menu a.router-link-active .prime-main-menu-item-count {
  opacity: 1;
}
.prime-main-menu a.router-link-active svg {
  opacity: 1;
}
.prime-main-menu a:hover .prime-main-menu-item {
  background: rgba(11, 71, 142, 0.5);
}
.prime-main-menu-icon {
  width: 20px;
  margin-left: 14px;
  margin-right: 14px;
}

/* menu width */
ion-menu {
  --width: 80px;
}
ion-menu ion-item {
  cursor: pointer;
}
ion-menu ion-content,
ion-menu ion-toolbar {
  --background: #0f5db8;
}
ion-menu ion-toolbar div {
  padding-top: 40px;
  padding-bottom: 40px;
}

ion-footer {
  background-color: #fff;
}

.link-dummy {
  color: var(--ion-color-primary, #3880ff);
  text-decoration: underline;
  cursor: pointer;
}

.message-balloon.blue a {
  color: #fff !important;
}

.primary-pathway {
  background: #ffffff33;
  border-radius: 5px;
}

.va-m {
  vertical-align: middle;
}
.va-s {
  vertical-align: super;
}

/* -------------------------------- */
/* Internal scroll shadow --------- */
/* -------------------------------- */

.prime-footer-scroll-fade-up {
  border-top: 2px solid var(--ion-color-white-shade);
  position: relative;
}

.prime-footer-scroll-fade-up::before {
  content: "";
  display: block;
  position: absolute;
  pointer-events: none;
  top: -22px;
  left: 0px;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(193, 193, 193, 0.15) 100%);
}

.prime-active-conversations-container {
  width: 320px;
}
.prime-active-conversations-container .prime-active-conversations-header {
  margin: 40px 20px 10px;
}
.prime-active-conversations-container .prime-active-conversations-header h5 {
  font-size: 20px !important;
  margin: 0 0 10px;
}

.yellow-pill {
  background-color: #fad265 !important;
  color: #000 !important;
}

.yellow-border-pill {
  background-color: #e9edf4 !important;
  border: 3px solid #fad265 !important;
}

.green-pill {
  background-color: var(--ion-color-success, #2dd36f) !important;
  border: 3px solid var(--ion-color-success);
  color: #fff !important;
}

/* -------------------------------- */
/* Media Queries ------------------ */
/* -------------------------------- */

@media (min-width: 768px) {
  .mobile {
    display: none !important;
  }
  .desktop {
    display: "" !important;
  }
}
@media (max-width: 767px) {
  .mobile {
    display: "" !important;
  }

  .desktop {
    display: none !important;
  }

  .prime-active-conversations-container {
    width: 100%;
  }

  ion-menu {
    --width: 250px;
  }

  .prime-main-menu-logo-container {
    background-color: transparent;
  }

  .prime-main-menu .prime-main-menu-item {
    width: 100%;
    margin: 0;
    padding-left: 10px;
  }

  .prime-main-menu .prime-main-menu-item.has-counter {
    padding-bottom: 0px;
  }

  .prime-active-conversations-container .prime-active-conversations-header {
    margin-top: 15px;
  }

  .prime-view-container.prime-left-menu {
    padding-left: 0px;
  }
}

@media (max-width: 767px) {
  .prime-tasks-table thead,
  .footer__row-count.vgt-pull-left {
    display: none !important;
  }
}

.mobile-hide {
  display: none;
}

.mobile-show {
  display: block !important;
  animation: fadeIn 0.3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
