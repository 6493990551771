export const formatDisplayName = (prefix, firstName, lastName, suffix) => {
  var formattedPrefix = "";
  var formattedName = firstName + " " + lastName;
  var formattedSuffix = "";
  if (prefix) {
    formattedPrefix = prefix + " ";
  }
  if (suffix) {
    formattedSuffix = ", " + suffix;
  }
  return formattedPrefix + formattedName + formattedSuffix;
};

export default {
  formatDisplayName
};
