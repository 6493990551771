import { getDiagnosisConsiderations } from "@/services/Api/getDiagnosisConsiderations";
import { postDiagnosisConsiderations } from "@/services/Api/postDiagnosisConsiderations";
import {
  getAddressedDocReferences,
  organizeMeasureReportsBySubCategory
} from "@engage/common-functions";
import { engageFhirApi } from "../../services/EngageFhirApi";

export default {
  namespaced: true,

  state: {
    lastFetchedId: null,
    diagnosisMeasureReports: [],
    diagnosisDocumentReferences: [],
    diagnosisUpToDateValueSet: [],
    categoryOrder: [
      "2024 PCV Attestation Program Details",
      "2023 PCV Attestation Program Details",
      "Prior Diagnosis Considerations (CLAIM)",
      "Prior Diagnosis Considerations (MOR)",
      "Prior Diagnosis Considerations",
      "Comorbidities",
      "Diagnosis Clarifications",
      "Incomplete Coding",
      "Suspected Diagnosis",
      "Suspected Diagnosis Considerations"
    ],
    attestationEditable: null
  },
  getters: {
    getDiagnosisMeasureReports(state) {
      return state.diagnosisMeasureReports;
    },

    getDiagnosisDocumentReferences(state) {
      return state.diagnosisDocumentReferences;
    },

    getAddressedDocRefs(state) {
      const flattenedMeasureReports = [];
      for (const report of state.diagnosisMeasureReports) {
        flattenedMeasureReports.push(...report.measureReports);
      }
      return getAddressedDocReferences(flattenedMeasureReports, state.diagnosisDocumentReferences);
    },

    getDiagnosisUpToDateValueSet(state) {
      return state.diagnosisUpToDateValueSet;
    },

    getAttestationEditable(state) {
      return state.attestationEditable;
    }
  },
  mutations: {
    mutateDiagnosisMeasureReports(state, payload) {
      const measureReports = payload;
      const orderedSubCategories = state.categoryOrder;
      state.diagnosisMeasureReports = organizeMeasureReportsBySubCategory(
        measureReports || [],
        orderedSubCategories,
        "Diagnosis Insights"
      );
    },

    mutateDiagnosisDocumentReferences(state, payload) {
      state.diagnosisDocumentReferences = payload;
    },

    mutateUpdatedDocRef(state, updatedDocumentReference) {
      const findExistingMatchedDocumentReferenceIndex = (docRef, potentialMatches) => {
        const measureIdIdentifier = docRef.identifier.find(
          x => x.system === "https://lumeris.com/fhir/CodeSystem/measure-report-identifier"
        );
        const measureId = measureIdIdentifier && measureIdIdentifier.value;

        const measureYearIdentifier = docRef.identifier.find(
          x => x.system === "https://lumeris.com/fhir/CodeSystem/measure-year"
        );
        const measureYear = measureYearIdentifier && measureYearIdentifier.value;

        return potentialMatches.findIndex(
          x =>
            x.identifier.some(y => {
              return (
                y.system === "https://lumeris.com/fhir/CodeSystem/measure-report-identifier" &&
                y.value === measureId
              );
            }) &&
            x.identifier.some(y => {
              return (
                y.system === "https://lumeris.com/fhir/CodeSystem/measure-year" &&
                y.value === measureYear
              );
            })
        );
      };

      const existingIndex = findExistingMatchedDocumentReferenceIndex(
        updatedDocumentReference,
        state.diagnosisDocumentReferences
      );

      if (existingIndex > -1) {
        state.diagnosisDocumentReferences.splice(existingIndex, 1, updatedDocumentReference);
      } else {
        state.diagnosisDocumentReferences.push(updatedDocumentReference);
      }
      // trigger stencil web component reactivity (splice is enough for view, but not stencil)
      state.diagnosisDocumentReferences = [...state.diagnosisDocumentReferences];
    },

    mutateDiagnosisUpToDateValueSet(state, payload) {
      state.diagnosisUpToDateValueSet = payload;
    },

    mutateAttestationEditable(state, payload) {
      state.attestationEditable = payload;
    }
  },
  actions: {
    /**
     * Diagnosis Considerations data call
     * @param {*} param0
     * @param {*} beneficiaryId
     */
    async fetchDCData({ commit, state }, beneficiaryId) {
      if (state.lastFetchedId == beneficiaryId) {
        return;
      }

      commit("mutateAttestationEditable", null);
      commit("mutateDiagnosisMeasureReports", null);
      commit("mutateDiagnosisDocumentReferences", null);
      commit("mutateDiagnosisUpToDateValueSet", null);

      const primeApiDxConsiderationsPromise = getDiagnosisConsiderations(beneficiaryId);
      const measureReportsPromise = engageFhirApi.get(`/MeasureReport/${beneficiaryId}`);
      const documentReferencesPromise = engageFhirApi.get(
        `/DocumentReference/beneficiary/${beneficiaryId}`
      );

      try {
        const [primeApiDxConsiderations, measureReports, documentReferences] = await Promise.all([
          primeApiDxConsiderationsPromise,
          measureReportsPromise,
          documentReferencesPromise
        ]);

        if (primeApiDxConsiderations) {
          commit("mutateAttestationEditable", primeApiDxConsiderations.isAttestationEditable);
        }

        if (measureReports) {
          commit("mutateDiagnosisMeasureReports", measureReports);
        }
        if (documentReferences) {
          commit("mutateDiagnosisDocumentReferences", documentReferences);
        }
      } catch (error) {
        khanSolo.error("Error fetching Diagnosis Considerations data", error);
      }

      state.lastFetchedId = beneficiaryId;
    },

    /**
     * POST updated record
     * @param {*} param0
     * @param {*} payload
     */
    async postUpdatedItem(payload) {
      await postDiagnosisConsiderations(payload);
    },

    async updateDocumentReference({ commit }, documentReference) {
      commit("mutateUpdatedDocRef", documentReference);
    }
  }
};
