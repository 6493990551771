<template>
  <div>
    <label for=""> {{ label }}</label>
    <div class="display-flex">
      <div class="input-wrapper">
        <input
          type="number"
          :disabled="disabled"
          :step="step"
          :min="min"
          :max="max"
          name=""
          :value="value"
          @input="onInput"
          @change="onInput"
        />
        <div v-if="unit" class="unit-section">
          {{ unit }}
        </div>
      </div>
      <div v-if="description" class="description-section">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NumberInputType",
  props: {
    label: {
      type: String,
      required: false
    },
    value: {
      type: Number,
      required: false
    },
    unit: {
      type: String,
      required: false
    },
    description: {
      type: String,
      required: false
    },
    min: {
      type: Number,
      required: false
    },
    max: {
      type: Number,
      required: false
    },
    step: {
      type: Number,
      required: false,
      default: 1
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    onInput(event) {
      this.$emit("input", event.target.value);
    }
  }
};
</script>
<style scoped>
label {
  margin: 10px 0;
  font-weight: bold;
  display: block;
}
.input-wrapper {
  border: 2px solid #ccc;
  border-radius: 6px;
  position: relative;
  padding: 1px;
}
input {
  padding: 7px;
  width: 100%;
  border: none;
  min-width: 200px;
}
.unit-section {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  margin: 0;
  width: 5rem;
  background: #ccc;
  text-align: center;
  color: #999;
  font-weight: bold;
  padding: 7px 5px;
}
.description-section {
  margin-left: 10px;
  margin-top: 10px;
  font-weight: bold;
  color: #999;
}
</style>
