import { I } from "@/utils/permissions";

export default [
  {
    path: "/programs",
    component: () => import("@/views/Home.vue"),
    children: [
      {
        path: "",
        name: "ProgramsList",
        component: () => import("@/views/ProgramsList.vue"),
        meta: { acl: [I.VIEW_PROGRAMS] }
      },
      {
        path: ":program_id/roster",
        name: "RosterProgram",
        component: () => import("@/components/Roster/RosterProgram.vue"),
        meta: { acl: [I.VIEW_PATIENT_DATA] }
      },
      {
        path: ":program_id/roster-eligible-patients",
        name: "RosterProgramEligiblePatients",
        component: () => import("@/components/Roster/RosterProgramEligiblePatients.vue"),
        meta: { acl: [I.VIEW_PATIENT_DATA] }
      },
      {
        path: "practice",
        name: "PracticeOverview",
        component: () => import("@/components/Settings/Practice/PracticeOverview.vue"),
        meta: { acl: [I.VIEW_ORGANIZATION] }
      },
      {
        path: ":program_id/overview",
        name: "ProgramDashboard",
        component: () => import("@/views/ProgramDashboard.vue"),
        meta: { acl: [I.VIEW_PROGRAMS] }
      },
      {
        path: ":program_id/outreaches",
        name: "OutreachesView",
        component: () => import("@/views/Outreaches.vue"),
        meta: { acl: [I.VIEW_PATIENT_DATA] }
      },
      {
        path: ":program_id/outreaches/:outreach_id",
        name: "TaskDetailsPRT",
        component: () => import("@/views/TaskDetailsPRT.vue"),
        meta: { acl: [I.VIEW_PATIENT_DATA] }
      },
      {
        path: ":program_id/add",
        name: "ProgramAddPatients",
        component: () => import("@/views/AddPatientsToProgram.vue"),
        meta: { acl: [I.VIEW_PROGRAMS] }
      }
    ]
  }
];
