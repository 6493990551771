export default [
  { text: "", value: "" },
  { text: "12:00AM", value: "0:00" },
  { text: "12:15AM", value: "0:15" },
  { text: "12:30AM", value: "0:30" },
  { text: "12:45AM", value: "0:45" },
  { text: "1:00AM", value: "1:00" },
  { text: "1:15AM", value: "1:15" },
  { text: "1:30AM", value: "1:30" },
  { text: "1:45AM", value: "1:45" },
  { text: "2:00AM", value: "2:00" },
  { text: "2:15AM", value: "2:15" },
  { text: "2:30AM", value: "2:30" },
  { text: "2:45AM", value: "2:45" },
  { text: "3:00AM", value: "3:00" },
  { text: "3:15AM", value: "3:15" },
  { text: "3:30AM", value: "3:30" },
  { text: "3:45AM", value: "3:45" },
  { text: "4:00AM", value: "4:00" },
  { text: "4:15AM", value: "4:15" },
  { text: "4:30AM", value: "4:30" },
  { text: "4:45AM", value: "4:45" },
  { text: "5:00AM", value: "5:00" },
  { text: "5:15AM", value: "5:15" },
  { text: "5:30AM", value: "5:30" },
  { text: "5:45AM", value: "5:45" },
  { text: "6:00AM", value: "6:00" },
  { text: "6:15AM", value: "6:15" },
  { text: "6:30AM", value: "6:30" },
  { text: "6:45AM", value: "6:45" },
  { text: "7:00AM", value: "7:00" },
  { text: "7:15AM", value: "7:15" },
  { text: "7:30AM", value: "7:30" },
  { text: "7:45AM", value: "7:45" },
  { text: "8:00AM", value: "8:00" },
  { text: "8:15AM", value: "8:15" },
  { text: "8:30AM", value: "8:30" },
  { text: "8:45AM", value: "8:45" },
  { text: "9:00AM", value: "9:00" },
  { text: "9:15AM", value: "9:15" },
  { text: "9:30AM", value: "9:30" },
  { text: "9:45AM", value: "9:45" },
  { text: "10:00AM", value: "10:00" },
  { text: "10:15AM", value: "10:15" },
  { text: "10:30AM", value: "10:30" },
  { text: "10:45AM", value: "10:45" },
  { text: "11:00AM", value: "11:00" },
  { text: "11:15AM", value: "11:15" },
  { text: "11:30AM", value: "11:30" },
  { text: "11:45AM", value: "11:45" },
  { text: "12:00PM", value: "12:00" },
  { text: "12:15PM", value: "12:15" },
  { text: "12:30PM", value: "12:30" },
  { text: "12:45PM", value: "12:45" },
  { text: "1:00PM", value: "13:00" },
  { text: "1:15PM", value: "13:15" },
  { text: "1:30PM", value: "13:30" },
  { text: "1:45PM", value: "13:45" },
  { text: "2:00PM", value: "14:00" },
  { text: "2:15PM", value: "14:15" },
  { text: "2:30PM", value: "14:30" },
  { text: "2:45PM", value: "14:45" },
  { text: "3:00PM", value: "15:00" },
  { text: "3:15PM", value: "15:15" },
  { text: "3:30PM", value: "15:30" },
  { text: "3:45PM", value: "15:45" },
  { text: "4:00PM", value: "16:00" },
  { text: "4:15PM", value: "16:15" },
  { text: "4:30PM", value: "16:30" },
  { text: "4:45PM", value: "16:45" },
  { text: "5:00PM", value: "17:00" },
  { text: "5:15PM", value: "17:15" },
  { text: "5:30PM", value: "17:30" },
  { text: "5:45PM", value: "17:45" },
  { text: "6:00PM", value: "18:00" },
  { text: "6:15PM", value: "18:15" },
  { text: "6:30PM", value: "18:30" },
  { text: "6:45PM", value: "18:45" },
  { text: "7:00PM", value: "19:00" },
  { text: "7:15PM", value: "19:15" },
  { text: "7:30PM", value: "19:30" },
  { text: "7:45PM", value: "19:45" },
  { text: "8:00PM", value: "20:00" },
  { text: "8:15PM", value: "20:15" },
  { text: "8:30PM", value: "20:30" },
  { text: "8:45PM", value: "20:45" },
  { text: "9:00PM", value: "21:00" },
  { text: "9:15PM", value: "21:15" },
  { text: "9:30PM", value: "21:30" },
  { text: "9:45PM", value: "21:45" },
  { text: "10:00PM", value: "22:00" },
  { text: "10:15PM", value: "22:15" },
  { text: "10:30PM", value: "22:30" },
  { text: "10:45PM", value: "22:45" },
  { text: "11:00PM", value: "23:00" },
  { text: "11:15PM", value: "23:15" },
  { text: "11:30PM", value: "23:30" },
  { text: "11:45PM", value: "23:45" }
];
