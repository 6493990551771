export default [
  {
    path: "/article/:toId/:fromId",
    name: "article",
    component: () => import("@/views/Article.vue")
  },
  {
    path: "/education/:id",
    name: "educationHome",
    component: () => import("@/views/Education.vue")
  }
];
