import { send } from "@/services/Api";

export const getDiagnosisConsiderations = async id => {
  const method = "get";
  const path = document.config.diagnosisConsiderations + id;
  try {
    const results = await send({ path, method, authToken: true });
    //ATTENTION FUTURE DEVS: This hack was added as a temporary req
    //to ensure a specific attestations measure shows first. Remove this hack once no longer needed --ww
    const compVisitIndex = results.data.diagnosisConsiderations.data.findIndex(p => p.category_name.includes("PCV Attestation Program Details"));

    if (compVisitIndex >= 0) {
      results.data.diagnosisConsiderations.data.unshift(results.data.diagnosisConsiderations.data.splice(compVisitIndex, 1)[0]);
    }

    return results.data.diagnosisConsiderations;
  } catch (error) {
    const errorDetails = {
      header: "Error",
      message: error
    };
    return {};
  }
};
