<template>
  <div>
    <div class="pad-top-twenty margin-bottom-thirty">
      <div class="scrollable">
        <div>
          <ion-row>
            <ion-col class="margin-bottom-ten">
              <h3>Quality Considerations - CDE Bulk Entry</h3>
            </ion-col>
          </ion-row>
          <ion-row v-for="(item, index) in filterUnSubmitted(this.cdeItems)" :key="item.cdsId + index + formSubmittedIds.length">
            <ion-col>
              <ion-card color="white" class="cde-row margin-death">
                <ModalClinicalData style="min-height: 480px;" :beneId="Number($route.params.id)" :item="item" :isCarded="true" />
              </ion-card>
            </ion-col>
          </ion-row>
        </div>
        <ion-row class="margin-top-ten pad-ten" v-if="this.cdeItems.length">
          <ion-col></ion-col>
          <ion-col><button type="button" class="prime-button button-primary button-block" @click="submitAll">Submit All</button></ion-col>
          <ion-col></ion-col>
        </ion-row>
      </div>
    </div>
  </div>
</template>

<script>
import ModalClinicalData from "./ModalClinicalData.vue";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events";
export default {
  name: "BulkCDE",
  components: {
    ModalClinicalData
  },
  data() {
    return {
      formSubmittedIds: [],
      cdeItems: [],
      gaps: [],
      isMarkedStatusEnabled: true
    };
  },
  beforeMount() {
    if (this.$route.params.cdeItems) {
      this.cdeItems = this.$route.params.cdeItems;
    } else {
      this.getGaps();
    }
  },
  async mounted() {
    this.beneId = this.$store.state.chatProfessional.currentPatient.beneficiary.id;
    EventBus.$on("QCFormSubmitted", formSubmittedId => {
      this.formSubmittedIds.push(formSubmittedId);
      this.filterUnSubmittedOnDeepLink();
    });
  },
  methods: {
    filterUnSubmitted(cdeFormParams) {
      if (!this.$route.params.cdeItems) {
        return cdeFormParams;
      }
      const formIds = cdeFormParams.map(item => item.cdeFormId);
      const toRemove = new Set(this.formSubmittedIds);
      const nonSubmitted = formIds.filter(form => !toRemove.has(form));
      const nonSubmittedForms = cdeFormParams.filter(frm => nonSubmitted.includes(frm.cdeFormId));

      if (!nonSubmitted.length) {
        this.$router.push("/patient-settings/quality-considerations/" + this.$route.params.id);
        return;
      }

      return nonSubmittedForms;
    },
    filterUnSubmittedOnDeepLink() {
      const formIds = this.cdeItems.map(item => item.cdeFormId);
      const toRemove = new Set(this.formSubmittedIds);
      const nonSubmitted = formIds.filter(form => !toRemove.has(form));
      const nonSubmittedForms = this.cdeItems.filter(frm => nonSubmitted.includes(frm.cdeFormId));

      if (!nonSubmitted.length) {
        this.$router.push("/patient-settings/quality-considerations/" + this.$route.params.id);
        return;
      }
      return nonSubmittedForms;
    },
    submitAll() {
      const submitters = document.querySelectorAll(".submitter");
      submitters.forEach(btn => {
        btn.click();
      });
    },
    async getGaps() {
      const method = "get";
      const path = document.config.gapsInCare + this.$route.params.id;

      httpSend({ path, method, authToken: true })
        .then(response => {
          const responseObject = response.data;
          this.gaps = responseObject.gapsInCares;
          this.isMarkedStatusEnabled = responseObject.isMarkedStatusEnabled

          this.isLoading = false;
          for (let key in this.gaps) {
            this.gaps[key].forEach(item => {
              if (item.cdeFormId && item.cdeStatus === "ready_for_entry") {
                //this.cdeFormsId.push(item.cdeFormId);
                this.cdeItems.push(item);
              } else {
                this.formSubmittedIds.push(item.cdeFormId);
              }
            });
          }

          if (!this.cdeItems.length) {
            this.$router.push("/patient-settings/quality-considerations/" + this.$route.params.id);
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.$ionic.toastController
            .create({
              header: "Failed to load Quality Considerations",
              message: error,
              duration: 7000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        });
    }
  }
};
</script>
<style scoped>
.cde-row >>> ion-content {
  /* border: 2px solid blue; */
  --background: transparent;
  min-height: inherit;
}

.cde-row >>> ion-toolbar {
  --background: transparent;
}
.no-programs {
  height: 100px;
}
</style>
