<template>
  <El v-if="!to" tag="button" class="prime-button" :class="classes" v-bind="$attrs" v-on="$listeners">
    <span><slot /></span>
    <ion-spinner name="dots" duration="1200" color="white"></ion-spinner>
  </El>
  <router-link v-else :to="to">
    <El tag="button" class="prime-button" :class="classes" v-bind="$attrs" v-on="$listeners">
      <slot />
    </El>
  </router-link>
</template>

<script>
const palette = {
  primary: "button-primary",
  secondary: "button-secondary"
};

const classMap = {
  block: "button-block",
  large: "large",
  pending: "button-pending"
};

export default {
  name: "PrimeButton",
  props: {
    block: Boolean,
    color: String,
    pending: Boolean,
    size: String,
    to: Object
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(palette[this.color] || "button-primary");
      this.pending && classes.push(classMap.pending);
      this.block && classes.push(classMap.block);
      classes.push(classMap[this.size]);
      return classes;
    }
  }
};
</script>

<style scoped>
.large {
  height: 50px;
  font-size: 14px;
  padding-top: 0;
}

button.prime-button.large:disabled {
  background-color: var(--ion-color-white);
  color: var(--ion-color-medium);
}
</style>
