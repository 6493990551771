<template>
  <El class="checkbox" :class="classes" v-bind="$attrs">
    <input :id="id" type="checkbox" v-on="$listeners" />
    <label :for="id"></label>
  </El>
</template>

<script>
import _ from "lodash";

const palette = {
  primary: undefined,
  secondary: "secondary"
};

const classMap = {
  large: "large"
};

export default {
  name: "PrimeCheckbox",
  props: {
    color: String,
    size: String
  },
  data() {
    return {
      id: _.uniqueId("checkbox_")
    };
  },
  computed: {
    classes() {
      const classes = [];
      classes.push(palette[this.color]);
      classes.push(classMap[this.size]);
      return classes;
    }
  }
};
</script>

<style scoped>
.checkbox.secondary label::before {
  border-color: var(--ion-color-medium);
}
.checkbox.secondary input:checked + label:before {
  border-color: var(--ion-color-primary);
}
.checkbox.large label::before {
  height: 25px;
  width: 25px;
}
.checkbox.large label::after {
  height: 6px;
  width: 12px;
  top: 9px;
  left: 7px;
}
</style>
