import languagePack from "@/assets/languagePack";

export default {
  install(Vue) {
    Vue.mixin({
      data() {
        return {
          $copy: undefined
        };
      },
      created() {
        if (this.$route) {
          this.$copy = languagePack[this.$route.name];
        }
      }
    });
  }
};
