import { I } from "@/utils/permissions";

const meta = {
  acl: [I.ACCESS_SECURE_WEB_CHAT],
  unauthorizedRoute: {
    name: "Lost"
  }
};

export default [
  {
    path: "/patient",
    component: () => import("@/views/HomePatient.vue"),
    children: [
      {
        path: "",
        name: "patientDefault",
        component: () => import("@/views/SecureChatPatient.vue"),
        meta
      },
      {
        path: "secure-chat",
        name: "secureChat",
        component: () => import("@/views/SecureChatPatient.vue"),
        meta
      }
    ]
  }
];
