const env = "srp";
var apiHost = window.apiHost || `https://api-${env}.mypcp.lumeris.io`;
var environment = window.environment || "srp";
var webSocketHost = window.webSocketHost || `wss://websocket-${env}.mypcp.lumeris.io`;
var debuggerHost = window.debuggerHost || `https://webhook-${env}.mypcp.lumeris.io/dbugga`;
const gridHost = window.gridHost || `https://grid-${env}.mypcp.lumeris.io`;
// Added for the datadog RUM monitoring
const datadogApplicationId =
  environment == "dev"
    ? "35989173-094f-402b-be80-b110bc4a96ff"
    : environment == "qa"
    ? "e5ad83a3-9a3d-4729-9784-9adaabe25ba0"
    : environment == "srp"
    ? "773d3dea-e625-454c-a19a-5ca7806b1e53"
    : environment == "prod"
    ? "560d8f95-8841-4470-8b6a-2ee0b621de19"
    : " ";
const datadogclientToken =
  environment == "dev"
    ? "pub4a54d7c2e18e1a522b336bfb92e1ee2c"
    : environment == "qa"
    ? "pub892a401c7e82fb19ac6fa7cf824dcd3c"
    : environment == "srp"
    ? "pub8646e7db0a90499286589a9ed242938b"
    : environment == "prod"
    ? "pubc9d75a7fea57c1d84e64c77ec1c635e4"
    : " ";
const engageFhirApiBaseUrl =
  environment == "dev"
    ? "https://api.webapp.prime.dev.hcs.cloud"
    : environment == "qa"
    ? "https://api.webapp.prime.qa.hcs.cloud"
    : environment == "srp"
    ? "https://webapp.uat.api.engage.lumeris.com"
    : environment == "prod"
    ? "https://webapp.api.engage.lumeris.com"
    : " ";

document.config = {
  engageFhirApiBaseUrl: engageFhirApiBaseUrl,
  applicationId: datadogApplicationId,
  clientToken: datadogclientToken,
  baseApiUrl: apiHost,
  environment: environment,
  webSocketUrl: webSocketHost,
  dbuggaWebhookUrl: debuggerHost,
  authApi: apiHost + "/api/v1/authenticate",
  authApiBene: apiHost + "/api/v1/authenticate/zeus",
  validateZeusToken: apiHost + "/api/v1/public/secureWebChatAccess/validateToken",
  refreshTokenApi: apiHost + "/api/v1/public/refresh",
  dashboardApi: apiHost + "/api/v1/dashboard/",
  healthtimelineApi: apiHost + "/api/v1/portal/healthTimeline/",
  messageApi: apiHost + "/api/v1/interaction/",
  recentConvosApi: apiHost + "/api/v1/portal/recentConversations/",
  convoAttachments: apiHost + "/api/v1/attachments/",
  messageUpdateApi: {
    professional: apiHost + "/api/v1/message",
    patient: apiHost + "/api/v1/zeus"
  },
  enrollmentApiPublic: apiHost + "/api/v1/public/enrollment/",
  enrollmentApi: apiHost + "/api/v1/enrollment/",
  demoEnrollmentApiPublic: apiHost + "/api/v1/public/demoEnrollment/",
  demoEnrollmentApprovalApi: apiHost + "/api/v1/demoEnrollment/",
  beneficiaryEnrollments: apiHost + "/api/v1/enrollment/beneficiary/",
  enrollmentApprovalApi: apiHost + "/api/v1/enrollment/",
  enrollmentDecline: apiHost + "/api/v1/enrollment/decline/beneficiary/",
  forgotPassword: apiHost + "/api/v1/public/security/ForgotPassword",
  setPassword: apiHost + "/api/v1/public/security/SetPassword",
  patientsApi: apiHost + "/api/v1/roster/",
  mrnSearchApi: apiHost + "/api/v1/roster/mrn",
  rosterApi: apiHost + "/api/v1/roster/full/",
  rosterDownload: apiHost + "/api/v1/roster/downloadRoster/",
  downloadExcelTemplate: apiHost + "/api/v1/files/patientUploadTemplate",
  uploadExcelTemplate: apiHost + "/api/v1/import",
  currentProfessionalAPI: apiHost + "/api/v1/portal/professionalAccount/",
  currentPatient: apiHost + "/api/v1/portal/beneficiaryAccount/",
  createInteraction: apiHost + "/api/v1/portal/beneficiaryAccount/",
  professionalNotificationSettings: apiHost + "/api/v1/professional/notifications/",
  tasksAPI: apiHost + "/api/v1/task",
  taskStatusUpdateApi: "/update/status",
  taskAssignedToUpdateApi: "/update/assignto",
  taskDueDateUpdateApi: "/update/duedate",
  templateMessagesApi: apiHost + "/api/v1/user_template/",
  templateMessagesSearchApi: apiHost + "/api/v1/user_template/search?terms=",
  patientSettings: apiHost + "/api/v1/portal/patientSettings/",
  professionalSettings: apiHost + "/api/v1/portal/professionalSettings/",
  patientMessages: apiHost + "/api/v1/zeus/messages",
  patientMessagesAndStatus: apiHost + "/api/v1/zeus/messagesAndStatus",
  newPatient: apiHost + "/api/v1/portal/patient/add",
  professional: apiHost + "/api/v1/professional/",
  newUser: apiHost + "/api/v1/professional/add",
  patientDuplicateCheck: apiHost + "/api/v1/portal/patient/duplicateCheck",
  userDuplicateCheck: apiHost + "/api/v1/professional/duplicateCheck",
  professionals: apiHost + "/api/v1/professional/byMyOrg",
  professionalRoster: apiHost + "/api/v1/professional/roster",
  defaultCareTeam: apiHost + "/api/v1/professional/defaultCareTeam/",
  sendTestEmail: apiHost + "/api/v1/professional/sendMeTestEmail",
  getEula: apiHost + "/api/v1/eula/careteam",
  careTeam: apiHost + "/api/v1/careteam/",
  confirmEula: apiHost + "/api/v1/eula",
  sendArticle: apiHost + "/api/v1/articleId",
  getArticle: {
    professional: apiHost + "/api/v1/education/article/",
    patient: apiHost + "/api/v1/public/education/article/"
  },
  articleSearch: apiHost + "/api/v1/education/search/",
  events: {
    interaction: "interaction",
    beneficiary: "beneficiary"
  },
  phiDetectionService: apiHost + "/api/v1/phi/detect/",
  resendZeusLink: apiHost + "/api/v1/secureWebChatAccess/interaction/",
  organization: apiHost + "/api/v1/organization/",
  organizationSettings: {
    get: apiHost + "/api/v1/getOrganizationAndSettings/",
    post: apiHost + "/api/v1/addOrganizationAndSettings",
    put: apiHost + "/api/v1/updateOrganizationAndSettings"
  },
  organizationsList: apiHost + "/api/v1/organization/metrics",
  diagnosisInsights: apiHost + "/api/v1/diagnosis_insights/",
  diagnosisConsiderations: apiHost + "/api/v1/diagnosis-considerations/",
  gapsInCare: apiHost + "/api/v1/gaps_in_care/",
  cdeForm: apiHost + "/api/v1/cde/form/",
  postCdeRequest: apiHost + "/api/v1/cde/request/",
  getCdeRequest: apiHost + "/api/v1/cde/request/",
  putCdeRequest: apiHost + "/api/v1/cde/request/",
  deleteCdeRequest: apiHost + "/api/v1/cde/request/",
  postCdeAttachment: apiHost + "/api/v1/cde/attachment/",
  getCdeAttachmentSignedUrl: apiHost + "/api/v1/cde/attachment/signedUrl",
  myOrganizations: apiHost + "/api/v1/organization/getMyOrgs",
  sdoh: apiHost + "/api/v1/sdoh_note/",
  SITPractice: apiHost + "/api/v1/keepintouch/tasks/allTasksByMyOrg",
  getTasksByProgramId: apiHost + "/api/v1/task/getAllTasksByProgramId/",
  getTaskById: apiHost + "/api/v1/keepintouch/tasks/getTaskById/",
  SITNextOpenTask: apiHost + "/api/v1/keepintouch/tasks/getNextOpenTask/",
  SITNextTask: apiHost + "/api/v1/keepintouch/tasks/getNextTask/",
  SITPreviousTask: apiHost + "/api/v1/keepintouch/tasks/getPreviousTask/",
  SITPreviousOpenTask: apiHost + "/api/v1/keepintouch/tasks/getPreviousOpenTask/",
  SITUpdateTask: apiHost + "/api/v1/keepintouch/tasks/",
  SITUpdateTaskComment: apiHost + "/api/v1/keepintouch/comments",
  SITGetTaskComments: apiHost + "/api/v1/keepintouch/comments/allComments/",
  SITOptIn: apiHost + "/api/v1/accountprogram/optInAddComment",
  task: apiHost + "/api/v1/task/",
  taskAssignment: apiHost + "/api/v1/assignment/task/",
  notificationAssignment: apiHost + "/api/v1/assignment/notification/",
  tinyUrl: apiHost + "/api/v1/tinyurl",
  SITUserAccount: apiHost + "/api/v1/accountprogram/account/",
  SITGetUserData: apiHost + "/api/v1/account/accountId/",
  SITUpdateUserData: apiHost + "/api/v1/accountchannel",
  accountChannel: apiHost + "/api/v1/account/",
  templateApiPublic: apiHost + "/api/v1/template/public",
  programsApi: apiHost + "/api/v1/program/",
  patientApi: apiHost + "/api/v1/beneficiary/",
  messagesStats: apiHost + "/api/v1/message/stats",
  eventEntry: apiHost + "/api/v1/event/create/",
  workRequest: apiHost + "/api/v1/work/request/send_broadcast_message",
  transcriptToEHR: apiHost + "/api/v1/work/request/",
  cacheApi: apiHost + "/api/v1/cache",
  rosterEligiblePatients: apiHost + "/api/v1/pas/program/",
  updateEligibilityStatus: apiHost + "/api/v1/pas/status/",
  eventHistory: apiHost + "/api/v1/event/history",
  cancelEvent: apiHost + "/api/v1/event/cancel",
  broadcastMessageHistory: apiHost + "/api/v1/broadcast_message/viaProfessional",
  broadcastMessageDescription: apiHost + "/api/v1/broadcast_message/patients/",
  voiceInvoke: apiHost + "/api/v1/voice/invoke",
  voiceStatus: apiHost + "/api/v1/voice/status/",
  logMessageAPI: apiHost + "/api/v1/logger/",
  gridApi: `${gridHost}/api`,
  virtualVisit: apiHost + "/api/v1/virtualvisit/initiate",
  medicalHistory: apiHost + "/api/v1/medical_history/all",
  pcvGuides: apiHost + "/api/v1/pcv-guides",
  patientOverviewPdf: apiHost + "/api/v1/patient-overview/",
  CDERejectionReasons: apiHost + "/api/v1/cde/rejection-reasons"
};
