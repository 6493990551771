/* eslint-disable no-console */
import { send } from "@/services/Api";

export default {
  log: message => {
    logMessage("info", message);
  },
  error: message => {
    logMessage("error", message);
  }
};

async function logMessage(logType, message) {
  if (document.config.environment !== "prod") {
    console.log(message);
    return;
  }

  if (message === null) {
    return;
  }

  const method = "post";
  const path = document.config.logMessageAPI + logType;
  const payload = message;

  try {
    const results = await send({ path, method, payload, authToken: true });
    return results.data;
  } catch (error) {
    //do nothing
  }
}
