import auth from "@/utils/Auth";
import { get } from "@/utils/permissions";
import storage, { KEYS } from "@/utils/storage";
import VueJwtDecode from "vue-jwt-decode";

export default {
  namespaced: true,
  state: () => ({
    accessToken: auth.getAccessToken(),
    refreshToken: auth.getRefreshToken(),
    zeusToken: storage.get(KEYS.ZEUS_TOKEN),
    orgData: auth.getOrgData() || null,
    security: auth.getSecurityData() || [],
    permissions: auth.getPermissionsData() || []
  }),
  mutations: {
    accessToken(state, payload) {
      if (payload) {
        auth.setAccessToken(payload);
      } else {
        storage.delete(KEYS.ACCESS_TOKEN);
      }
      state.accessToken = payload;
    },
    refreshToken(state, payload) {
      if (payload) {
        auth.setRefreshToken(payload);
      } else {
        storage.delete(KEYS.REFRESH_TOKEN);
      }
      state.refreshToken = payload;
    },
    zeusToken(state, payload) {
      if (payload) {
        storage.set(KEYS.ZEUS_TOKEN, payload, { expires: "1D" });
      } else {
        storage.delete(KEYS.ZEUS_TOKEN);
      }
      state.zeusToken = payload;
    },
    setOrgData(state, payload) {
      if (payload) {
        state.orgData = payload;
        auth.setOrgData(payload);
      } else {
        state.orgData = null;
        auth.removeOrgData(KEYS.ORG_DATA);
      }
    },
    setSecurityData(state, payload) {
      if (payload) {
        state.security = payload;
        auth.setSecurityData(payload);
      } else {
        state.security = null;
        auth.removeSecurityData();
      }
    },

    setPermissionsData(state, payload) {
      if (payload) {
        auth.setPermissionsData(payload);
        state.permissions = payload;
      } else {
        state.permissions = null;
        auth.removePermissionsData();
      }
    }
  },
  getters: {
    authenticated({ accessToken }) {
      return !!accessToken;
    },
    permissions({ accessToken }) {
      return get(accessToken);
    },

    /**
     * Returns organization id from state
     * @param {*} state
     * @returns organizationId
     */
    getOrganizationsData(state) {
      return state.orgData;
    },

    getSecurityData(state) {
      return state.security;
    }
  },
  actions: {
    setAuthTokens({ commit }, payload) {
      const { access_token, refresh_token, zeus_token, security_object } = payload;
      const jwt = VueJwtDecode.decode(access_token);
      
      if (security_object !== null) {
        commit("setSecurityData", security_object);
      } else {
        commit("setPermissionsData", jwt.permission);
      }

      commit("accessToken", access_token);
      commit("refreshToken", refresh_token);
      commit("zeusToken", zeus_token);
    },
    clearAccess({ commit }) {
      commit("accessToken", null);
      commit("refreshToken", null);
      commit("setOrgData", null);
      commit("setSecurityData", null);
      commit("setPermissionsData", null);
    }
  }
};
