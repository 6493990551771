import getMessages from "@/services/Api/getMessages";
import getActiveConvos from "@/services/Api/getActiveConvos";
import getConvosCount from "@/services/Api/getConvosCount";

export default {
  namespaced: true,
  state: {
    activeConvos: [],
    convoInitiatorType: "direct",
    convosCounts: {
      direct: {
        totalRecords: "<i>Loading...</i>",
        awaitingResponse: "<i>Loading...</i>"
      },
      system: "<i>Loading...</i>"
    },
    convosOrderby: "needsResponse",
    convosPageNum: 1,
    convosPerPage: 20,
    currentInteractionId: undefined,
    newInteraction: false,
    totalRecords: 0,
    messages: [],
    messagesLoading: false,
    activeConvosLoading: true,
    beginningOfConvos: false,
    messagesPayload: {},
    atBeginningOfMessages: false,
    beginningOfChatTxt: {
      body: "Beginning of Chat",
      channel: "sms",
      direction: "none",
      id: null
    },
    scrollListening: false,
    convosScrollListening: false,
    currentPatient: {}
  },
  mutations: {
    activeConvos(state, payload) {
      state.activeConvos = payload;
    },
    convoInitiatorType(state, payload) {
      state.convoInitiatorType = payload;
    },
    convosCounts(state, payload) {
      state.convosCounts = payload;
    },
    convosOrderby(state, payload) {
      state.convosOrderby = payload;
    },
    convosPageNum(state, payload) {
      state.convosPageNum = payload;
    },
    currentInteractionId(state, payload) {
      state.currentInteractionId = payload;
    },
    enableConvosLoader(state) {
      state.activeConvosLoading = true;
    },
    disableConvosLoader(state) {
      state.activeConvosLoading = false;
    },
    toggleNewInteraction(state) {
      state.newInteraction = !state.newInteraction;
    },
    totalRecords(state, payload) {
      state.totalRecords = payload;
    },
    messages(state, payload) {
      state.messages = [...state.messages, ...payload];
    },
    clearMessages(state) {
      state.messages = [];
    },
    clearMessagesPayload(state) {
      state.messagesPayload = {};
    },
    messagesPayload(state, payload) {
      state.messagesPayload = payload;
    },
    toggleMessagesLoading(state, payload) {
      state.messagesLoading = payload;
    },
    initializeMessages(state) {
      state.messages = [];
      state.atBeginningOfMessages = false;
      state.scrollListening = false;
    },
    toggleScrollListening(state) {
      state.scrollListening = !state.scrollListening;
    },
    turnScrollListeningOn(state) {
      state.scrollListening = true;
    },
    turnScrollListeningOff(state) {
      state.scrollListening = false;
    },
    turnConvosScrollListeningOn(state) {
      state.convosScrollListening = true;
    },
    turnConvosScrollListeningOff(state) {
      state.convosScrollListening = false;
    },
    setCurrentPatient(state, payload) {
      state.currentPatient = payload;
    }
  },
  getters: {
    activeConvos(state) {
      return state.activeConvos;
    },
    convosCounts(state) {
      return state.convosCounts;
    },
    convoInitiatorType(state) {
      return state.convoInitiatorType;
    },
    convosOrderby(state) {
      return state.convosOrderby;
    },
    convosPageNum(state) {
      return state.convosPageNum;
    },
    currentInteractionId(state) {
      return state.currentInteractionId;
    },
    newInteraction(state) {
      return state.newInteraction;
    },
    activeConvosLoading(state) {
      return state.activeConvosLoading;
    },
    messages(state) {
      return state.messages;
    },
    messagesPayload(state) {
      return state.messagesPayload;
    },
    messagesLoading(state) {
      return state.messagesLoading;
    },
    atBeginningOfMessages(state) {
      return state.atBeginningOfMessages;
    },
    beginningOfConvos(state) {
      return state.beginningOfConvos;
    },
    scrollListening(state) {
      return state.scrollListening;
    },
    convosScrollListening(state) {
      return state.convosScrollListening;
    }
  },
  actions: {
    async getConvosCounts({ commit }) {
      let directConvos = undefined;
      let systemConvos = undefined;
      directConvos = await getConvosCount("direct");
      systemConvos = await getConvosCount("system");
      let counts = {
        direct: {
          totalRecords: directConvos.totalRecords,
          awaitingResponse: directConvos.awaitingResponse
        },
        system: systemConvos.totalRecords
      };
      commit("convosCounts", counts);
    },
    async getPartialActiveConvos({ commit, state }) {
      let convos = undefined;
      convos = await getActiveConvos(this.state.chatProfessional.convoInitiatorType, this.state.chatProfessional.convosPageNum, this.state.chatProfessional.convosPerPage, this.state.chatProfessional.convosOrderby);
      const comboConvos = [...state.activeConvos, ...convos];

      if (comboConvos.length == state.totalRecords) {
        state.beginningOfConvos = true;
      }
      commit("activeConvos", comboConvos);
      commit("disableConvosLoader");
    },
    async updateCurrentActiveConvos({ commit, state }) {
      let perPage;
      if (state.newInteraction) {
        perPage = state.activeConvos.length + 1;
        commit("toggleNewInteraction");
      } else {
        perPage = state.activeConvos.length;
      }

      let convos = undefined;
      convos = await getActiveConvos(this.state.chatProfessional.convoInitiatorType, 1, perPage, this.state.chatProfessional.convosOrderby);
      if (convos.length == state.totalRecords) {
        state.beginningOfConvos = true;
      }
      commit("activeConvos", convos);
      commit("disableConvosLoader");
    },
    async setConvoInitiatorType({ commit, state }, payload) {
      commit("disableConvosLoader");
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convoInitiatorType", payload);
    },
    async convoInitiatorType({ commit, state }, payload) {
      commit("turnConvosScrollListeningOff");
      state.activeConvos = [];
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convoInitiatorType", payload);
    },
    async convosOrderby({ commit, state }, payload) {
      commit("disableConvosLoader");
      state.activeConvos = [];
      state.beginningOfConvos = false;
      state.convosPageNum = 1;
      commit("convosOrderby", payload);
    },
    async convosPageNum({ commit }, payload) {
      commit("convosPageNum", payload);
    },
    async currentInteractionId({ commit }, payload) {
      commit("currentInteractionId", payload.id);
    },
    async updateMessages({ commit, dispatch, state }, payload) {
      const { id, pageNumber, messagesPerPage } = payload;
      const results = await getMessages(id, pageNumber, messagesPerPage);
      const lastSMSMessage = results.messages.filter(message => message.channel === "sms").slice(-1)[0];

      dispatch("dbugga/updateDbuggaMode", lastSMSMessage, { root: true });
      commit("messagesPayload", results);

      if (results.messages.length < messagesPerPage && !state.atBeginningOfTime) {
        state.atBeginningOfMessages = true;
        results.messages.push(state.beginningOfChatTxt);
        commit("messages", results.messages);
      } else if (results.messages.length > 0) {
        commit("messages", results.messages);
      }
    }
  }
};
