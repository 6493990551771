import { whatAmI } from "@/utils/context";
import store from "@/store";
import auth from "@/utils/Auth";

function checkSecurityData(obj) {
  if (obj && Object.keys(obj).length) {
    if (Object.keys(obj).length === 1) {
      return "/professional/roster";
    } else {
      return "/professional/dashboard";
    }
  } else {
    return "/professional/roster";
  }
}

function getRootPath(to) {
  const securityData = auth.getSecurityData(); //store.getters["security/getSecurityData"];

  const pathMap = {
    professional: checkSecurityData(securityData),
    lumerisadmin: "/programs/stayintouch/practice",
    clientpartner: "/programs/stayintouch/tasks",
    patient: "/patient"
  };

  const unknown = to.query.token ? "/login/?token=" + to.query.token : "/login";
  return pathMap[whatAmI()] || unknown;
}

export default [
  {
    path: "/",
    redirect: to => getRootPath(to)
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
    beforeEnter(to, from, next) {
      store.commit("resetStore", null, { root: true });
      store.dispatch("security/clearAccess");
      store.dispatch("organizations/clearAccess");
      next();
    }
  },
  {
    path: "/login/callback",
    name: "loginCallback",
    component: () => import("@/components/Login/Callback/SSOCallback.vue")
  },
  {
    path: "/forgot",
    name: "forgotPassword",
    component: () => import("@/views/ForgotPassword.vue")
  },
  {
    path: "/reset",
    name: "resetPassword",
    component: () => import("@/views/ResetPassword.vue")
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {
      store.commit("resetStore", null, { root: true });
      window.localStorage.removeItem("roster.filterModel");
      store.dispatch("security/clearAccess");
      store.dispatch("organizations/clearAccess");
      next();
    },
    redirect: "/login"
  },
  {
    path: "/compliance-public",
    name: "CompliancePublic",
    component: () => import("@/views/CompliancePublic.vue"),
    props: true
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () => import("@/views/Privacy.vue")
  },
  {
    path: "/terms-conditions",
    name: "terms-conditions",
    component: () => import("@/views/TermsAndConditions.vue")
  },
  {
    path: "/success",
    name: "success",
    component: () => import("@/components/Global/Success.vue")
  },
  {
    path: "/lost",
    name: "Lost",
    component: () => import("@/components/Global/PageNotFound.vue")
  },
  {
    path: "/unauthorized",
    name: "Unauthorized",
    component: () => import("@/components/Global/Unauthorized.vue")
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/components/Global/PageNotFound.vue")
  }
];
